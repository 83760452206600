import { mdiLockOutline } from "@mdi/js";
import Icon from "../../assets/svg/Icon";
import CheckIcon from "../../assets/svg/CheckIcon";
import { ListOption } from "../ListOption/ListOption";
import { IMyPlanData, IOfferData } from "../../types/plan.type";

interface PlanItemProps {
    offerData: IOfferData;
    myPlanData: IMyPlanData;
    checked?: boolean;
    handleClick: () => void;
    lockedItem?: boolean;
}

export const PlanItem = ({
    offerData,
    myPlanData,
    checked = false,
    handleClick,
    lockedItem = false,
}: PlanItemProps) => {
    return (
        <div className=" flex flex-col flex-grow px-2">
            <div
                className={` border rounded-lg rounded-b-sm p-2 lg:h-[210px] md:h-[210px] min-w-[255px] pb-2 ${
                    !lockedItem
                        ? checked
                            ? "border-orange-500"
                            : "hover:border-neutral-300"
                        : ""
                } ${checked ? "bg-[#FDF2EE]" : "bg-neutral-50"} ${
                    lockedItem
                        ? "!bg-neutral-500 text-neutral-900 relative"
                        : "cursor-pointer text-neutral-900"
                }`}
                onClick={() => {
                    !lockedItem && handleClick();
                }}
            >
                <div className="min-w-[255px]">
                    {lockedItem && (
                        <div className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="flex flex-col">
                                <Icon
                                    materialIcon={mdiLockOutline}
                                    className={`w-15`}
                                />
                                <div className="font-semibold text-2xl">
                                    Expired
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-row pt-2">
                        <div className=" flex flex-col font-bold text-md flex-grow">
                            <span>
                                {offerData?.title1}
                                {!!offerData?.recommended && (
                                <span
                                    className={`border rounded-md w-fit px-1 font-medium text-xs border-orange-500 text-orange-500 mb-2 ml-2`}
                                    >
                                        Recommended
                                    </span>
                                )}
                            </span>
                            <span>{offerData?.title2}</span>
                        </div>
                        {!lockedItem && (
                            <div className="pt-1">
                                {checked ? (
                                    <Icon
                                        className="w-[32px] mr-2 text-orange-600"
                                        icon={"CheckCircleIcon"}
                                    />
                                ) : (
                                    <Icon
                                        className="w-[32px] mr-2 text-neutral-300"
                                        icon={"BlankCircleIcon"}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {offerData?.titleDescription && <div className="text-xs font-normal" dangerouslySetInnerHTML={{ __html: offerData?.titleDescription }}></div>}
                </div>
                <div className="flex flex-row align-center mt-2 min-w-[255px] pt-2">
                    <span className={`text-xl font-bold mr-1`}>
                        ${offerData?.price}
                    </span>
                    <span
                        className={`font-base pt-1 text-neutral-900 font-normal text-sm`}
                    >
                        {!!offerData?.priceDescription?.length && " / "}
                        {offerData?.priceDescription}
                    </span>
                </div>
                <div
                    className={`text-sm  whitespace-pre-line font-normal mt-4 min-w-[255px] ${
                        lockedItem ? "text-neutral-900" : "text-neutral-400"
                    }`}
                >
                    {offerData?.planInfo}
                </div>
            </div>
            <div className="flex flex-col align-baseline border rounded-lg rounded-t-sm bg-neutral-50 mt-2 lg:h-[270px] md:h-[270px] min-w-[255px]">
                {myPlanData?.planBenefits?.length && (
                    <div className="mt-4 px-4">
                        <ListOption
                            options={myPlanData?.planBenefits}
                            itemStyle={"!mb-1 items-center text-neutral-600"}
                            icon={
                                <CheckIcon
                                    width={12}
                                    height={12}
                                    className="text-orange-500 w-5"
                                />
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
