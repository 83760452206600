import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError, setAuth } from "../authentication/userSlice";
import {
    IPaginatedData,
    IPaginatedDataInitialState,
} from "../../types/dataTypes";
import { TIME_PERIOD } from "../../constants";
import { UserStatusEnum } from "../plans/constants";
import { UserTypeVariant } from "../../components/UserType/UserType";

export interface AdminState {
    isLoading: Boolean;
    error: string | null;
    trips: IPaginatedData;
    users: IPaginatedData;
    insights: IPaginatedData;
    reviews: IPaginatedData;
    hotels: IPaginatedData;
    others: IPaginatedData;
    restaurants: IPaginatedData;
    complaints: IPaginatedData;
    overview: any;
    selectedUser: any;
    allPlans: any;
    allTransfers: IPaginatedData;
}

const initialState: AdminState = {
    isLoading: false,
    error: "",
    trips: IPaginatedDataInitialState,
    users: IPaginatedDataInitialState,
    selectedUser: null,
    insights: IPaginatedDataInitialState,
    reviews: IPaginatedDataInitialState,
    hotels: IPaginatedDataInitialState,
    others: IPaginatedDataInitialState,
    restaurants: IPaginatedDataInitialState,
    complaints: IPaginatedDataInitialState,
    overview: {},
    allPlans: null,
    allTransfers: IPaginatedDataInitialState,
};

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.trips = { ...action.payload.trips };
        },
        setUsers: (state, action: PayloadAction<{ users: any }>) => {
            state.users = { ...action.payload.users };
        },
        setInsights: (state, action: PayloadAction<{ insights: any }>) => {
            state.insights = { ...action.payload.insights };
        },
        setComplaints: (state, action: PayloadAction<{ complaints: any }>) => {
            state.complaints = { ...action.payload.complaints };
        },
        setReviews: (state, action: PayloadAction<{ reviews: any }>) => {
            state.reviews = { ...action.payload.reviews };
        },
        setHotels: (state, action: PayloadAction<{ hotels: any }>) => {
            state.hotels = { ...action.payload.hotels };
        },
        removeHotel: (
            state,
            action: PayloadAction<{
                hotelId: number;
            }>
        ) => {
            state.hotels = {
                ...state.hotels,
                count: state.hotels.count - 1,
                data: state.hotels?.data?.filter(
                    (u) => Number(u?.id) !== Number(action.payload.hotelId)
                ),
            };
        },
        updateHotel: (
            state,
            action: PayloadAction<{
                hotel: any;
            }>
        ) => {
            state.hotels = {
                ...state.hotels,
                data: state.hotels?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.hotel?.id)
                        ? u
                        : action.payload.hotel
                ),
            };
        },
        updateRestaurant: (
            state,
            action: PayloadAction<{
                restaurant: any;
            }>
        ) => {
            state.restaurants = {
                ...state.restaurants,
                data: state.restaurants?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.restaurant?.id)
                        ? u
                        : action.payload.restaurant
                ),
            };
        },
        updateOther: (
            state,
            action: PayloadAction<{
                other: any;
            }>
        ) => {
            state.others = {
                ...state.others,
                data: state.others?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.other?.id)
                        ? u
                        : action.payload.other
                ),
            };
        },
        removeRestaurant: (
            state,
            action: PayloadAction<{
                restaurantId: number;
            }>
        ) => {
            state.restaurants = {
                ...state.restaurants,
                count: state.restaurants.count - 1,
                data: [
                    ...state.restaurants?.data?.filter(
                        (u) =>
                            Number(u?.id) !==
                            Number(action.payload.restaurantId)
                    ),
                ],
            };
        },
        removeOther: (
            state,
            action: PayloadAction<{
                otherId: number;
            }>
        ) => {
            state.others = {
                ...state.others,
                count: state.others.count - 1,
                data: state.others?.data?.filter(
                    (u) => Number(u?.id) !== Number(action.payload.otherId)
                ),
            };
        },
        setOthers: (state, action: PayloadAction<{ others: any }>) => {
            state.others = { ...action.payload.others };
        },
        setRestaurants: (
            state,
            action: PayloadAction<{ restaurants: any }>
        ) => {
            state.restaurants = { ...action.payload.restaurants };
        },
        setOverview: (state, action: PayloadAction<{ overview: any }>) => {
            state.overview = { ...action.payload.overview };
        },
        setSelectedUser: (
            state,
            action: PayloadAction<{ selectedUser: any }>
        ) => {
            state.selectedUser = action.payload.selectedUser;
        },
        setAllPlans: (state, action: PayloadAction<{ allPlans: any }>) => {
            state.allPlans = action.payload.allPlans;
        },
        setAllTransfers: (
            state,
            action: PayloadAction<{ allTransfers: any }>
        ) => {
            state.allTransfers = action.payload.allTransfers;
        },
        updateUserOnList: (
            state,
            action: PayloadAction<{
                user: any;
            }>
        ) => {
            state.users = {
                ...state.users,
                data: state.users?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.user?.id)
                        ? u
                        : { ...u, ...action.payload.user }
                ),
            };
        },
        updateTripOnList: (
            state,
            action: PayloadAction<{
                trip: any;
            }>
        ) => {
            state.trips = {
                ...state.trips,
                data: state.trips?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.trip?.id)
                        ? u
                        : { ...u, ...action.payload.trip }
                ),
            };
        },
        removeTrip: (
            state,
            action: PayloadAction<{
                tripId: number;
            }>
        ) => {
            state.trips = {
                ...state.trips,
                count: state.trips.count - 1,
                data: state.trips?.data?.filter(
                    (u) => Number(u?.id) !== Number(action.payload.tripId)
                ),
            };
        },
        removeComplaint: (
            state,
            action: PayloadAction<{
                complaintId: number;
            }>
        ) => {
            state.complaints = {
                ...state.complaints,
                count: state.complaints.count - 1,
                data: state.complaints?.data?.filter(
                    (u) => Number(u?.id) !== Number(action.payload.complaintId)
                ),
            };
        },
        updateComplaintOnList: (
            state,
            action: PayloadAction<{
                complaint: any;
            }>
        ) => {
            state.complaints = {
                ...state.complaints,
                data: state.complaints?.data?.map((u) =>
                    Number(u?.id) !== Number(action.payload.complaint?.id)
                        ? u
                        : { ...u, ...action.payload.complaint }
                ),
            };
        },
    },
});

export const {
    isLoading,
    setTrips,
    setUsers,
    setInsights,
    setReviews,
    setHotels,
    setOthers,
    setRestaurants,
    setOverview,
    setComplaints,
    removeHotel,
    removeRestaurant,
    removeOther,
    updateHotel,
    updateOther,
    updateRestaurant,
    setSelectedUser,
    updateUserOnList,
    updateTripOnList,
    removeTrip,
    removeComplaint,
    updateComplaintOnList,
    setAllPlans,
    setAllTransfers,
} = adminSlice.actions;

//SELECTORS
export const selectAdminTrips = (state: RootState) => state.admin?.trips;
export const selectAdminUsers = (state: RootState) => state.admin?.users;
export const selectAdminInsights = (state: RootState) => state.admin?.insights;
export const selectAdminReviews = (state: RootState) => state.admin?.reviews;
export const selectAdminHotels = (state: RootState) => state.admin?.hotels;
export const selectAdminOthers = (state: RootState) => state.admin?.others;
export const selectAdminPlans = (state: RootState) => state.admin?.allPlans;
export const selectAdminTransfers = (state: RootState) => state.admin?.allTransfers;
export const selectAdminSelectedUser = (state: RootState) =>
    state.admin?.selectedUser;
export const selectAdminRestaurants = (state: RootState) =>
    state.admin?.restaurants;
export const selectOverview = (state: RootState) => state.admin?.overview;
export const selectAdminComplaints = (state: RootState) =>
    state.admin?.complaints;

export const getAdminTrips =
    (
        search: string,
        dateFrom: Date,
        dateTo: Date,
        take: number,
        skip: number
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setTrips({
                    trips: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminTripsList(
                search,
                dateFrom,
                dateTo,
                take,
                skip
            );
            dispatch(
                setTrips({
                    trips: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setTrips({
                    trips: { ...IPaginatedDataInitialState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };

export const getAdminUsers =
    (
        search: string,
        dateFrom: Date,
        dateTo: Date,
        take: number,
        skip: number
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setUsers({
                    users: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminUsersList(
                search,
                dateFrom,
                dateTo,
                take,
                skip
            );
            dispatch(
                setUsers({
                    users: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setUsers({
                    users: { ...IPaginatedDataInitialState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };

export const getAdminInsights =
    (
        take: number,
        skip: number,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setInsights({
                    insights: {
                        ...IPaginatedDataInitialState,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getAdminInsights(
                take,
                skip,
                type,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setInsights({
                    insights: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setInsights({
                    insights: {
                        ...IPaginatedDataInitialState,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };
export const getAdminReviews =
    (
        take: number,
        skip: number,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setReviews({
                    reviews: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminReviews(
                take,
                skip,
                type,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setReviews({
                    reviews: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setReviews({
                    reviews: {
                        ...IPaginatedDataInitialState,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };

export const getAdminHotels =
    (
        take: number,
        skip: number,
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setHotels({
                    hotels: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminHotels(
                take,
                skip,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setHotels({
                    hotels: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setHotels({
                    hotels: { ...IPaginatedDataInitialState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };

export const getAdminRestaurants =
    (
        take: number,
        skip: number,
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setRestaurants({
                    restaurants: {
                        ...IPaginatedDataInitialState,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getAdminRestaurants(
                take,
                skip,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setRestaurants({
                    restaurants: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setRestaurants({
                    restaurants: {
                        ...IPaginatedDataInitialState,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };

export const getAdminOthers =
    (
        take: number,
        skip: number,
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setOthers({
                    others: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminOthers(
                take,
                skip,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setOthers({
                    others: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setOthers({
                    others: { ...IPaginatedDataInitialState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };
export const updateReviewAdmin =
    (review: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            const response = await serverApi.updateReviewAdmin(review);
            return response.data;
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const deleteReviewAdmin =
    (reviewId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteReviewAdmin(reviewId);
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const changeUserRole =
    (userId: number, role: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.changeUserRole(userId, role);
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const getUserAdminToken =
    (userEmail: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            const token = await serverApi.getUserAdminToken(userEmail);
            setAuth({ auth: { token: token.data } });
            const siteURL = `${window.location.protocol}//${window.location.host}`;
            window.location.replace(
                `${siteURL}/otp?otp=${token.data}&redirectUrl=/home`
            );
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const changeUserStatus =
    (userEmail: string, status: UserStatusEnum): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.changeUserStatus(userEmail, status);
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const deleteUserStatus =
    (userEmail: string): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteUserStatus(userEmail);
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const getOverview =
    (
        period: TIME_PERIOD,
        startDate: Date | null,
        endDate: Date | null,
        userType: UserTypeVariant | string
    ): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(setOverview({ overview: {} }));
        try {
            const response = await serverApi.getAdminOverview(
                period,
                startDate,
                endDate,
                userType
            );
            dispatch(setOverview({ overview: response.data }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const deleteHotel =
    (hotelId: number): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteHotel(hotelId);
            dispatch(removeHotel({ hotelId }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const deleteRestaurant =
    (restaurantId: number): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteRestaurant(restaurantId);
            dispatch(removeRestaurant({ restaurantId }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const deleteOther =
    (otherId: number): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteOther(otherId);
            dispatch(removeOther({ otherId }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const replaceHotel =
    (hotelId: number, newHotelId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                await serverApi.replaceHotel(hotelId, newHotelId);
                dispatch(removeHotel({ hotelId }));
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const replaceRestaurant =
    (restaurantId: number, newRestaurantId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                await serverApi.replaceRestaurant(
                    restaurantId,
                    newRestaurantId
                );
                dispatch(removeRestaurant({ restaurantId }));
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const replaceOther =
    (otherId: number, newOtherId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                await serverApi.replaceOther(otherId, newOtherId);
                dispatch(removeOther({ otherId }));
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const getUserData =
    (userId: number): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(setSelectedUser({ selectedUser: null }));
        try {
            const response = await serverApi.getUser(userId);
            dispatch(setSelectedUser({ selectedUser: response.data }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const updateUserData =
    (userId: number, fields: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                const response = await serverApi.updateUserData(userId, fields);
                const currentState = getState().admin.selectedUser || {};
                dispatch(
                    setSelectedUser({
                        selectedUser: { ...currentState, ...response.data },
                    })
                );
                dispatch(
                    updateUserOnList({
                        user: response.data,
                    })
                );
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const updateUserProfileData =
    (userId: number, fields: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                const response = await serverApi.updateUserProfileData(
                    userId,
                    fields
                );
                const currentState = getState().admin.selectedUser || {};
                dispatch(
                    setSelectedUser({
                        selectedUser: {
                            ...currentState,
                            profileExtra: response.data,
                        },
                    })
                );
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const updateTrip =
    (trip: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                const response = await serverApi.updateTrip(trip.id, trip);
                dispatch(updateTripOnList({ trip: response.data }));
                dispatch(isLoading(false));
                resolve(response.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                reject();
            }
        });
    };

export const deleteTrip =
    (tripId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.removeTrip(tripId);
            dispatch(removeTrip({ tripId }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const getAdminComplaints =
    (
        take: number,
        skip: number,
        status: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date,
        itemType: string = ""
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setComplaints({
                    complaints: {
                        ...IPaginatedDataInitialState,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getComplaints(
                take,
                skip,
                status,
                search,
                dateFrom,
                dateTo,
                itemType
            );
            dispatch(
                setComplaints({
                    complaints: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setComplaints({
                    complaints: IPaginatedDataInitialState,
                })
            );
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };
export const deleteComplaint =
    (complaintId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.deleteComplaintAdmin(complaintId);
            dispatch(removeComplaint({ complaintId }));
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const updateComplaint =
    (complaint: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                const response = await serverApi.updateComplaint(complaint);
                dispatch(updateComplaintOnList({ complaint: response.data }));
                dispatch(isLoading(false));
                resolve(response.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                reject();
            }
        });
    };

export const getComplaintItem = async (complaint: any) => {
    const response = await serverApi.getComplaintItem(complaint);
    return response.data;
};

export const reportComplaint =
    (complaint: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            dispatch(isLoading(true));
            try {
                await serverApi.newComplaint(complaint);
                resolve(true);
            } catch (error) {
                dispatch(handleError(error));
                reject(false);
            } finally {
                dispatch(isLoading(false));
            }
        });
    };

export const addSubscriptionForAdmin =
    (userId: number, priceId: string, callback: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.addSubscriptionToUserForAdmin(userId, priceId);
            callback && callback();
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const addSubscriptionToUser =
    (planId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.addSubscriptionToUser(planId);
            window.location.reload();
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const extendSubscriptionToUser =
    (
        subscriptionId: number,
        days: number,
        userId: number,
        callback?: any
    ): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.extendSubscriptionToUser(
                subscriptionId,
                days,
                userId
            );
            callback && callback();
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const getAllPlans = (): AppThunk => async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(setAllPlans({ allPlans: null }));
    try {
        const response = await serverApi.getAllPlans();
        dispatch(setAllPlans({ allPlans: response.data }));
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(isLoading(false));
    }
};

// export const fetchAllTransfers = (): AppThunk => async (dispatch, getState) => {
//     try {
//         dispatch(isLoading(true));
//         const response = await serverApi.getPaymentsAdmin();
//         dispatch(setAllTransfers({ allTransfers: response.data }));
//         dispatch(isLoading(false));
//     } catch (error) {
//         dispatch(isLoading(false));
//         dispatch(handleError(error));
//     }
// }

export const getAdminTransfers =
    (
        take: number,
        skip: number,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setAllTransfers({
                    allTransfers: { ...IPaginatedDataInitialState, isLoading: true },
                })
            );
            const response = await serverApi.getAdminTransfers(
                take,
                skip,
                type,
                search,
                dateFrom,
                dateTo
            );
            dispatch(
                setAllTransfers({
                    allTransfers: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );
            return response?.data;
        } catch (error) {
            dispatch(
                setAllTransfers({
                    allTransfers: {
                        ...IPaginatedDataInitialState,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };


export const savePlanPermissions =
    (
        planId: number,
        permissions: any[],
        onSuccess: Function
    ): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));

        try {
            await serverApi.savePlanPermissions(planId, permissions);
            const response = await serverApi.getAllPlans();
            dispatch(setAllPlans({ allPlans: response.data }));
            onSuccess();
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const sendFeedback =
    (payload: any): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.newComplaint(payload);
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export const addSubscriptionToOrganization =
    (planId: number, organizationId: number): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.addSubscriptionToOrganization(planId, organizationId);
            window.location.reload();
        } catch (error) {
            dispatch(handleError(error));
        } finally {
            dispatch(isLoading(false));
        }
    };

export default adminSlice.reducer;
