import React from "react";
import { isValidUrl } from "../../helpers";

export const DescriptionText = ({ text }: { text?: string }) => {
    const textArray = text?.split(/(https?:\/\/[^\s]+|www\.[^\s]+)/g) || [];

    return (
        <span>
            {textArray.map((item, index) => {
                if (isValidUrl(item)) {
                    return (
                        <a
                            key={index}
                            href={item}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item}
                        </a>
                    );
                } else {
                    return <span key={index}>{item}</span>;
                }
            })}
        </span>
    );
};
