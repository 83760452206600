import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import { PAGE_ROWS, UserRoles } from "../../../constants";
import { UsersDotMenuKeys } from "../../../constants/admin";
import {
    getAdminUsers,
    changeUserRole,
    selectAdminUsers,
    getUserData,
    selectAdminSelectedUser,
    setSelectedUser,
    changeUserStatus,
    getUserAdminToken,
    deleteUserStatus,
} from "../adminSlice";
import AllUsersList from "./AllUsersList";
import UserProfile from "./UserProfile";
import { parseMomentUtc } from "../../../helpers";
import { UserStatusEnum } from "../../plans/constants";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";

export default function AllUsers(props: any) {
    const dispatch = useAppDispatch();
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const users = useAppSelector(selectAdminUsers);
    const selectedUser = useAppSelector(selectAdminSelectedUser);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(4, "years").startOf("day").toDate()
    );
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    useEffect(() => {
        getData();
        return () => {
            dispatch(setSelectedUser({ selectedUser: undefined }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, startDate, endDate]);

    const getData = () => {
        dispatch(
            getAdminUsers(
                search,
                parseMomentUtc(startDate).startOf("day").toDate(),
                parseMomentUtc(endDate).endOf("day").toDate(),
                PAGE_ROWS,
                newPage?.skip
            )
        );
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };

    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleClickDotsMenu = async (option: any, user: any) => {
        switch (option) {
            case UsersDotMenuKeys.makeAdmin:
                await dispatch(changeUserRole(user.id, UserRoles.ADMIN));
                getData();
                break;
            case UsersDotMenuKeys.revokeAdmin:
                await dispatch(changeUserRole(user.id, "BASIC"));
                getData();
                break;
            case UsersDotMenuKeys.activateUser:
                await dispatch(
                    changeUserStatus(user.email, UserStatusEnum.ACTIVE)
                );
                getData();
                break;
            case UsersDotMenuKeys.deleteUser:
                setDeleteUserModalOpen(user);
                break;
            case UsersDotMenuKeys.getAdminToken:
                await dispatch(getUserAdminToken(user.email));
                break;
            case UsersDotMenuKeys.edit:
                dispatch(getUserData(user.id));
                break;
        }
    };

    const onDeletedUser = async (user: any) => {
        await dispatch(deleteUserStatus(user.email));
        getData();
    };

    return !selectedUser ? (
        <>
            <ModalConfirmation
                open={!!deleteUserModalOpen}
                title="Are you sure you want to delete this user from FamGuru?"
                handleCancel={() => {
                    setDeleteUserModalOpen(null);
                }}
                handleSubmit={() => {
                    onDeletedUser(deleteUserModalOpen);
                    setDeleteUserModalOpen(null);
                }}
                okButtonText={"Confirm"}
            />
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Users List
            </div>
            <div className="grid grid-cols-12 md:items-end gap-5 md:gap-10">
                <div className="col-span-12 md:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-6 md:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-6 md:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="endDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
            <AllUsersList
                users={users}
                handleClickDotsMenu={handleClickDotsMenu}
                handlePaginatorChange={handlePaginatorChange}
                getData={getData}
            />

            {!users?.isLoading && !users?.data.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no users to show.
                </div>
            )}
        </>
    ) : (
        <UserProfile
            profile={selectedUser}
            onGoBack={() => {
                dispatch(setSelectedUser({ selectedUser: null }));
            }}
        />
    );
}
