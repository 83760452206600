import * as yup from "yup";
import Button from "../../../../../../../../components/ButtonSquare";
import { InsightImages } from "../../../../../components/InsightImages";
import TextAreaInput from "../../../../../../../../components/TextArea";
import { useEffect, useMemo, useState } from "react";
import { useInsightState } from "../../../../../../../../hooks/useInsightState";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";
import InsightHeader from "../../components/InsightHeader";
import NumberRating from "../../../../../../../../components/NumberRating/NumberRating";
import SelectInputWithValid from "../../../../../../../../components/InputSelect";
import { goodForWhichClientsOptions } from "../../../../../../../../constants";
import { addTripActivityReview } from "../../../../../../tripSlice";
import Icon from "../../../../../../../../assets/svg/Icon";
import { BlockModal } from "../../../../../../../../components/Modal/BllockerModal";
import { InsightMedia } from "../../../../../components/InsightMedia";

interface InsightNotesFormProps {
    closeForm: () => void;
    insightKey: "hotelInsight" | "restaurantInsight" | "otherInsight";
    getDefaultValues?: (insight?: Record<string, any>) => Record<string, any>;
    addInsightNotes: (
        tripId: number,
        activityId: number,
        payload: Record<string, any>,
        insightId?: number
    ) => any;
}

const schema = yup
    .object({
        stars: yup
            .number()
            .min(1)
            .max(10)
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("This field is required"),
        // General comments are review comments
        generalComments: yup.string().max(5000).nullable().notRequired(),
        // Private comments are private notes
        privateComments: yup.string().max(5000).nullable().notRequired(),
        goodForWhichClients: yup.array().nullable().notRequired(),
        specificClients: yup.string().max(5000).nullable().notRequired(),
    })
    .required("This field is required");

export default function InsightNotesForm({
    closeForm,
    insightKey,
    getDefaultValues,
    addInsightNotes,
}: InsightNotesFormProps) {
    const {
        activityId,
        tripId,
        dispatch,
        handleUploadFile,
        control,
        register,
        errors,
        handleSubmit,
        reset,
        setValue,
        isValid,
        activityReview,
        isDirty,
        ...insightState
    } = useInsightState({
        initialItem: null,
        schema,
    });
    const [currentStars, setCurrentStars] = useState(0);
    const insight = insightState?.[insightKey];
    const review = activityReview;

    useEffect(() => {
        setCurrentStars(review?.stars || 0);
        const defaultValues = getDefaultValues?.(insight) || insight || {};
        reset({
            ...defaultValues,
            stars: review?.stars || 0,
            generalComments: review?.comments,
            privateComments: insight?.generalComments || insight?.comments,
            goodForWhichClients: insight?.goodForWhichClients?.split(","),
            specificClients: insight?.specificClients,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insight, review]);

    const handleCancel = () => {
        closeForm();
    };

    const handleSubmitForm = async (data: any) => {
        const {
            stars,
            generalComments,
            newImages,
            modifiedImages,
            deletedImages,
            newVideos,
            modifiedVideos,
            deletedVideos,
            privateComments,
            ...rest
        } = data;
        const payloadReview = {
            stars,
            comments: generalComments,
            newImages,
            modifiedImages,
            deletedImages,
            newVideos,
            modifiedVideos,
            deletedVideos,
        };

        const payloadNotes = {
            ...rest,
            stars,
            goodForWhichClients: data?.goodForWhichClients?.join(","),
            [insightKey === "hotelInsight" ? "generalComments" : "comments"]:
                privateComments,
        };

        await Promise.allSettled([
            dispatch(
                addTripActivityReview(
                    Number(tripId),
                    Number(activityId),
                    payloadReview,
                    review?.id
                )
            ),
            dispatch(
                addInsightNotes(
                    Number(tripId),
                    Number(activityId),
                    payloadNotes,
                    insight?.insightId
                )
            ),
        ]);
        closeForm();
    };

    const handleStarsChange = (value: number) => {
        setValue("stars", value, { shouldValidate: true, shouldDirty: true });
        setCurrentStars(value);
    };

    const showCancelButton = !!insight?.insightId && !!insight?.stars;

    return (
        <div className="flex flex-col text-left">
            <InsightHeader showEditButton={false} onEdit={() => {}} />
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="col-span-6 sm:col-span-3 mt-3">
                    <div className="flex flex-grow text-left text-lg font-semibold items-center text-neutral-900 mb-4">
                        <Icon icon={"EarthIcon"} className={`w-5 h-5 mr-2`} />
                        Public notes
                    </div>
                    <div className="flex-grow text-left text-sm font-medium text-neutral-600 mb-4">
                        These notes will be shared with all members
                    </div>
                    <div className="flex-grow text-left text-base font-semibold text-neutral-900 mb-4">
                        Rate your experience
                        <span className="text-red-500 pl-1">*</span>
                    </div>
                    <NumberRating
                        className="cursor-pointer w-8"
                        rating={currentStars}
                        onChange={handleStarsChange}
                        error={errors["stars"]?.message as string}
                    />
                </div>
                <div className="mt-5">
                    <div className="mt-5">
                        <InsightMedia
                            handleUploadFile={handleUploadFile}
                            images={activityReview?.images || []}
                            videos={activityReview?.videos || []}
                        />
                    </div>
                </div>
                <div className="w-full mt-5">
                    <TextAreaInput
                        register={register}
                        label="Public comments"
                        type="text"
                        defaultValue={
                            insight?.generalComments || insight?.comments
                        }
                        name={"generalComments"}
                        error={errors["generalComments"]?.message}
                    />
                </div>
                <div className="bg-neutral-100 p-5 rounded-lg mt-10">
                    <div className="flex items-center flex-grow text-left text-lg font-semibold text-neutral-900 mb-4">
                        <Icon icon={"LockIcon"} className={`w-5 h-5 mr-2`} />
                        Private notes
                    </div>
                    <div className="flex-grow text-left text-sm font-medium text-neutral-600 mb-4">
                        Add private comments about your experience
                    </div>
                    <div className="w-full mt-5">
                        <TextAreaInput
                            className="bg-neutral-100"
                            register={register}
                            label="Your private comments"
                            type="text"
                            defaultValue={insight?.privateComments}
                            // id="privateComments"
                            name="privateComments"
                            error={errors["privateComments"]?.message}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-3 mt-10">
                        <InsightFormContainer>
                            <InsightInputContainer>
                                <SelectInputWithValid
                                    control={control}
                                    isMulti={true}
                                    label="Good for which clients"
                                    placeholder="Select"
                                    options={goodForWhichClientsOptions}
                                    defaultValue={
                                        insight?.goodForWhichClients?.length
                                            ? insight?.goodForWhichClients?.split(
                                                  ","
                                              )
                                            : undefined
                                    }
                                    id="goodForWhichClients"
                                    name="goodForWhichClients"
                                    error={
                                        errors["goodForWhichClients"]?.message
                                    }
                                    style={{
                                        background: "#F4F3F3",
                                    }}
                                />
                            </InsightInputContainer>
                        </InsightFormContainer>
                    </div>
                    <div className="w-full mt-10">
                        <TextAreaInput
                            className="bg-neutral-100"
                            register={register}
                            label="Specific clients that would like this."
                            placeholder="E.g: Mr. Smith, families, couples, etc."
                            type="text"
                            defaultValue={insight?.specificClients}
                            name="specificClients"
                            error={errors["specificClients"]?.message}
                        />
                    </div>
                </div>
                <div className="p-8 text-right flex gap-5 w-full lg:justify-end">
                    <BlockModal shouldBlock={isDirty} />
                    {showCancelButton && (
                        <Button
                            onClick={handleCancel}
                            outlined
                            label="Cancel"
                        />
                    )}
                    <Button type="submit" disabled={!isValid} label={"Save"} />
                </div>
            </form>
        </div>
    );
}
