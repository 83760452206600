import Modal, { Modal_Size } from ".";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { Paragraph } from "../Paragraph/Paragraph";
import { useNavigate } from "react-router";

export interface ModalProps {
    open?: boolean;
    handleClose: () => void;
    usersLimit?: number;
    hasSubscription?: boolean;
    addingAdmins?: boolean;
}

export const UpgradePlanModal: React.FunctionComponent<ModalProps> = (
    props
) => {
    const { open, handleClose, usersLimit = 1, addingAdmins } = props;
    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate("/plans");
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            hideCloseButton
            size={Modal_Size.small}
        >
            <div className="m-4">
                <div className="text-xl text-center font-semibold mb-2 text-neutral-900">
                {addingAdmins ? "Admin" : "Member"} limit reached
                </div>

                <div className="text-center mb-4">
                    <Paragraph className="text-sm text-neutral-600">
                        Unfortunately, your current plan only allows up to{" "}
                        {usersLimit}{" "}
                        {addingAdmins ? "administrator" : "member"}(s) per
                        trip.{" "}
                        <a
                            href="https://famguru.app/pricing"
                            target="_blank"
                            rel="noreferrer"
                            className="underline-offset-4 text-sm font-semibold mb-6 whitespace-nowrap text-orange-500"
                        >
                            Check out here
                        </a>{" "}
                        our available plans to see your options.
                    </Paragraph>
                </div>
                <div className="flex flex-row gap-6 justify-between px-4">
                    <ButtonSquared
                        label="Keep Current"
                        outlined
                        className="px-6"
                        onClick={handleClose}
                        size={ButtonSize.SMALL}
                    />
                    <ButtonSquared
                        label="Go to account"
                        className="px-6"
                        onClick={handleSubmit}
                        size={ButtonSize.SMALL}
                    />
                </div>
            </div>
        </Modal>
    );
};
