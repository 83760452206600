import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal, { Modal_Size } from ".";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { UserTypeVariant, UserType } from "../UserType/UserType";
import { updateUserType } from "../../features/authentication/userSlice";
import { ChoosePlanModal } from "./ChoosePlanModal";
import WorkIcon from "../../assets/svg/work_outline.svg";
import CompassIcon from "../../assets/svg/compass-outline.svg";
import { useNavigate } from "react-router";
import { ListOption } from "../ListOption/ListOption";
import CheckIcon from "../../assets/svg/CheckIcon";
import Icon from "../../assets/svg/Icon";

export interface ModalProps {
    open?: boolean;
    handleClose: () => void;
    currentUserType?: UserTypeVariant;
}

export const UserTypeModal: React.FunctionComponent<ModalProps> = (props) => {
    const [currentUserType, setCurrentUserType] = useState<UserTypeVariant>();
    const { open, handleClose: onClose } = props;

    const [showPlanModal, setShowPlanModal] = useState(false);
    const [showTrialModal, setShowTrialModal] = useState(false);
    const [showFreePlanModal, setShowFreePlanModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose = () => {
        setCurrentUserType(undefined);
        setShowPlanModal(false);
        setShowTrialModal(false);
        setShowFreePlanModal(false);
        onClose();
    };

    const handleSelectUserType = (variant: UserTypeVariant) => {
        currentUserType === variant
            ? setCurrentUserType(undefined)
            : setCurrentUserType(variant);
    };

    const handleSubmit = async () => {
        if (currentUserType) {
            dispatch(
                updateUserType({ userType: currentUserType }, async () => {
                    currentUserType === UserTypeVariant.SUPPLIER
                        ? setShowPlanModal(true)
                        : setShowFreePlanModal(true);
                })
            );
        }
    };

    const freeplanBenefits: string[] = [
        "Accept Fam invitations from Suppliers",
        "Set up your own self-directed Fam Trips*",
        "Store your best photos",
        "Share insights with clients and colleagues",
        "Keep your Fam Trip Archive at your fingertips forever",
    ];
    const freePlanDisclaimer =
        "*Self-directed Fam trips valid for one member only in free version.";

    useEffect(() => {
        if (props?.currentUserType) {
            handleSelectUserType(props.currentUserType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.currentUserType]);

    const handlePlanChanged = (plan: any) => {
        if (plan?.defaultPlan) {
            setShowPlanModal(false);
            setShowTrialModal(true);
        } else {
            handleClose();
        }
    };

    if (showPlanModal) {
        return (
            <ChoosePlanModal
                open={showPlanModal}
                handleClose={() => {
                    setShowPlanModal(false);
                }}
                showDefault={true}
                onChangePlan={handlePlanChanged}
                userType={UserTypeVariant.SUPPLIER}
                closeText={"Back"}
                forceCloseModal={() => {
                    setShowPlanModal(false);
                    handleClose();
                }}
            />
        );
    }

    if (showTrialModal) {
        return (
            <Modal
                hideCloseButton
                open={showTrialModal}
                size={Modal_Size.small}
                className="min-h-16"
            >
                <div className="py-2 px-4">
                    <div className="text-xl text-center font-semibold mb-4">
                        Get started with a complimentary trip planning
                        experience!
                    </div>
                    <div className="text-base text-center font-normal text-neutral-800 mb-6">
                        Explore FamGuru's features so you can find the perfect
                        plan for you.
                        <br />
                        No credit card required.
                    </div>
                    <div className="flex flex-row gap-4 mt-4 w-full justify-center items-center text-right">
                        <ButtonSquared
                            outlined
                            label="Save it for it later"
                            onClick={handleClose}
                            className="p-4"
                            size={ButtonSize.FIT}
                        />
                        <ButtonSquared
                            label="Start free trip now"
                            onClick={() => {
                                handleClose();
                                navigate("/trips/new");
                            }}
                            className="p-4"
                            size={ButtonSize.FIT}
                        />
                    </div>
                    <div className="text-center mt-4">
                        <span className="text-sm font-semibold mb-6 whitespace-nowrap text-orange-500">
                            <a
                                href="https://famguru.app/pricing"
                                target="_blank"
                                rel="noreferrer"
                                className="underline-offset-4 text-sm font-semibold mb-6 whitespace-nowrap text-orange-500"
                            >
                                {"I want to know more about Pricing Plans >"}
                            </a>
                        </span>
                    </div>
                </div>
            </Modal>
        );
    }

    if (showFreePlanModal) {
        return (
            <Modal
                open={showFreePlanModal}
                size={Modal_Size.small}
                className="min-h-32"
                onClose={handleClose}
                hideCloseButton
            >
                <div className="py-2 px-4">
                    <div className="text-xl text-center font-semibold mb-4">
                        Your plan as an advisor
                    </div>
                    <div className="bg-rose border rounded-lg border-orange-500 text-base text-center font-normal text-neutral-800 py-4 px-6">
                        <div className="flex flex-row items-center pb-2">
                            <div className="text-lg flex-1 text-left text-neutral-900 font-semibold mb-2">
                                Free
                            </div>
                            <div>
                                <Icon
                                    className="w-[32px] mr-1 text-orange-600"
                                    icon={"CheckCircleIcon"}
                                />
                            </div>
                        </div>
                        <div className="pb-4 mb-2 text-sm text-left border-b border-b-1 border-b-neutral-300 ">
                            All Travel Advisors receive a complimentary
                            subscription to FamGuru, today and always.
                        </div>

                        <ListOption
                            itemStyle="items-center text-xs mb-1"
                            options={freeplanBenefits}
                            icon={
                                <CheckIcon
                                    width={16}
                                    className="text-orange-500 mr-2"
                                />
                            }
                        />
                        <div className="text-left text-xs text-neutral-400">
                            {freePlanDisclaimer}
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 mt-6 w-full justify-end items-center text-right">
                        <ButtonSquared
                            outlined
                            label="Back"
                            onClick={() => setShowFreePlanModal(false)}
                            className="p-4"
                            size={ButtonSize.FIT}
                        />
                        <ButtonSquared
                            label="Continue"
                            onClick={() => {
                                handleClose();
                            }}
                            className="p-4"
                            size={ButtonSize.FIT}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal hideCloseButton open={open} size={Modal_Size.xs}>
            <div>
                <div className="text-xl text-center font-semibold mb-2 text-neutral-900">
                    Get Started on FamGuru!
                </div>

                <div className="text-center mb-4">
                    <span className="lg:text-right text-sm whitespace-nowrap text-neutral-600">
                        First, select the role that fits you best.
                    </span>
                </div>
                <div className="flex">
                    <UserType
                        variant={UserTypeVariant.ADVISOR}
                        title={"Advisor"}
                        description={
                            "Capture your Fam Trip notes and share knowledge reports."
                        }
                        icon={CompassIcon}
                        checked={currentUserType === UserTypeVariant.ADVISOR}
                        handleClick={() =>
                            handleSelectUserType(UserTypeVariant.ADVISOR)
                        }
                    />
                </div>
                <div className="flex">
                    <UserType
                        variant={UserTypeVariant.SUPPLIER}
                        title={"Supplier"}
                        description={"Create and share travel experiences."}
                        icon={WorkIcon}
                        checked={currentUserType === UserTypeVariant.SUPPLIER}
                        handleClick={() =>
                            handleSelectUserType(UserTypeVariant.SUPPLIER)
                        }
                    />
                </div>

                <div className="flex flex-row gap-6 w-full justify-end text-right">
                    <ButtonSquared
                        disabled={!currentUserType}
                        label="Continue"
                        onClick={handleSubmit}
                        size={ButtonSize.NORMAL}
                    />
                </div>
            </div>
        </Modal>
    );
};
