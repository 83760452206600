import { useAppSelector } from "../../../../../app/hooks";
import { selectProfile } from "../../../../authentication/userSlice";
import {
    selectedTrip,
} from "../../../tripSlice";

import TripRequirementAdmin from "./TripRequirementAdmin";
import TripRequirementAdvisor from "./TripRequirementAdvisor";

export default function TripRequirement() {
    const trip = useAppSelector(selectedTrip);
    const profile = useAppSelector(selectProfile);

    const canEdit = trip?.canEdit || profile?.isAdmin;
    return (canEdit ? <TripRequirementAdmin /> : <TripRequirementAdvisor />);
}
