import { MouseEventHandler } from "react";
import Avatar from "../../../../components/Avatar";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
import { UserRoleOnTrip } from "../../../../constants";

const ParticipantRole = {
    [UserRoleOnTrip.ADMIN]: "Admin",
    [UserRoleOnTrip.MEMBER]: "Participant",
    [UserRoleOnTrip.OWNER]: "Primary Admin",
    [UserRoleOnTrip.VIEWER]: "Viewer",
};

interface IParticipantsTable {
    isLoading: boolean;
    member: any;
    handleClick: MouseEventHandler;
    canEdit?: boolean;
}
export const MemberCard = ({
    member,
    handleClick,
    canEdit,
}: IParticipantsTable) => {
    return (
        <div
            onClick={() => {
                return handleClick(member);
            }}
            className={`p-4 w-full ${
                canEdit ? "hover:bg-neutral-50 cursor-pointer" : ""
            }`}
        >
            <div className="text-neutral-900 text-sm flex w-full">
                <div className="items-baseline">
                    <Avatar
                        src={
                            !!member?.user?.avatar_thumb?.length
                                ? member?.user?.avatar_thumb
                                : member?.user?.avatar
                        }
                        className="w-6 h-6"
                    />
                </div>
                <div className="ml-4 w-full">
                    <span className="text-left text-sm font-semibold">
                        {member.user?.firstName
                            ? `${member.user?.firstName} ${member.user?.lastName}`
                            : member?.userEmail}
                    </span>
                    <div className="text-neutral-900 text-sm">
                        {member.user?.agency}
                    </div>
                    <div className="w-full flex justify-between items-center">
                        <div className="text-neutral-600 text-sm">
                            {ParticipantRole[member.role as UserRoleOnTrip]}
                        </div>
                        <div className="text-neutral-900 text-sm">
                            <StatusBadgeComponent status={member.status} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
