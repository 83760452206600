import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js";
import Icon from "../../../../../assets/svg/Icon";
import Avatar from "../../../../../components/Avatar";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import { TripUserRoleOptions } from "../../../../../constants";
import { getStatusFromFiles } from "../../../../../helpers";

export default function RequirementUsersList(props: {
    participants: any[];
    onUserClick: (u: any) => any;
    selectedUsers: any[];
    onUserSelected: any;
}) {
    const { participants, onUserClick, selectedUsers, onUserSelected } = props;
    return (
        <>
            {participants?.map((u: any) => {
                const roleLabel = TripUserRoleOptions.find(
                    (rO) => rO.value === u?.role
                );
                const selected = selectedUsers.includes(u.userEmail);
                return (
                    <div
                        key={u.userEmail}
                        className={
                            "hover:bg-neutral-50 cursor-pointer py-4 border-b border-b-gray-300"
                        }
                        onClick={() => onUserClick(u)}
                    >
                        <div className="w-full flex justify-between items-center">
                            <div className="flex gap-3 items-center justify-start">
                                <div
                                    style={{ width: "50px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onUserSelected(u);
                                    }}
                                >
                                    {selected ? (
                                        <Icon
                                            materialIcon={mdiCheckboxMarked}
                                            className="w-7 cursor-pointer text-orange-600 rounded-lg text-xs bg-white"
                                        />
                                    ) : (
                                        <Icon
                                            materialIcon={
                                                mdiCheckboxBlankOutline
                                            }
                                            className="w-7 cursor-pointer text-neutral-800"
                                        />
                                    )}
                                </div>
                                <Avatar
                                    src={
                                        !!u?.user?.avatar_thumb?.length
                                            ? u?.user?.avatar_thumb
                                            : u?.user?.avatar
                                    }
                                    className="w-6 h-6"
                                />

                                <div className="text-left">
                                    {u.user?.firstName
                                        ? `${u.user?.firstName} ${u.user?.lastName}`
                                        : u?.userEmail}
                                </div>
                            </div>
                            <div className="">
                                <StatusBadgeComponent
                                    status={getStatusFromFiles(
                                        u.requirementFiles
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex gap-3 text-left text-xs text-gray-400 items-center justify-start mt-2">
                            <div className="w-full">
                                <div>{roleLabel?.label}</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
