import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/ButtonSquare";
import InputSearch from "../../../../components/InputSearch";
import { ButtonSize, PAGE_ROWS, PAGE_SECTIONS } from "../../../../constants";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../layout/layoutSlice";
import {
    getTripRequirements,
    getTripRequirementsMobile,
    selectedTrip,
} from "../../tripSlice";
import TripRequirements from "./TripRequirements";
import { selectProfile } from "../../../authentication/userSlice";

export default function TripRequirementsWrapper(props: any) {
    const trip = useAppSelector(selectedTrip);
    const [search, setSearch] = useState<string>("");
    const [newPage, setNewPage] = useState<any>({});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useAppSelector(selectCurrentBreakpoint);

    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip?.id) {
            getRequirements(
                PAGE_ROWS,
                newPage.skip,
                newPage.currentPage,
                search
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, trip?.id, search]);

    const getRequirements = (
        take: number,
        newSkip: number,
        newPage: number,
        search: string
    ) => {
        const getAction = isMobile
            ? getTripRequirementsMobile
            : getTripRequirements;
        if (trip?.id) {
            dispatch(getAction(trip?.id, take, newSkip, newPage, search));
        }
    };

    const handleSearchChange = (value: string) => {
        setSearch((prev) => {
            setNewPage({
                skip: 0,
                currentPage: 1,
            });
            return value;
        });
    };

    const handleNewReqClick = (e: any) => {
        navigate("new");
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    return (
        <>
            <div className="align-middle inline-block min-w-full">
                <div className="flex flex-row-reverse items-start flex-wrap-reverse lg:flex-row gap-4 w-full pb-8">
                    <div className="w-full lg:w-auto text-left self-center">
                        <InputSearch
                            onChange={handleSearchChange}
                            name="search"
                            id="search"
                            placeholder="Search"
                        />
                    </div>
                    {(profile?.isAdmin ||
                        (trip?.canEdit && !trip?.suspended)) && (
                        <div className="flex-grow text-right self-center">
                            <Button
                                label="Create Requirement"
                                onClick={handleNewReqClick}
                                size={ButtonSize.SMALL}
                            />
                        </div>
                    )}
                </div>
                <TripRequirements
                    handlePaginatorChange={handlePaginatorChange}
                    handleNext={handlePaginatorChange}
                />
            </div>
        </>
    );
}
