import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../../components/TextArea";
import {
    addTripRequirementFile,
    selectedTripRequirement,
    uploadTempFile,
} from "../../../tripSlice";
import axios from "axios";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import DropFileInput from "../../../../../components/DropFileInput/DropFileInput";
import fileImage from "../../../../../assets/images/files.png";
import InputErrorLabel from "../../../../../components/InputErrorLabel";
import CheckBox from "../../../../../components/CheckBox";
const schema = yup
    .object({
        description: yup.string().max(5000).nullable(),
    })
    .required("This field is required");

export default function TripRequirementFileForm({
    onSubmit,
    userEmail,
    requirement,
}: {
    onSubmit: any;
    userEmail?: string;
    requirement?: any;
}) {
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);
    let { tripId } = useParams();
    const selectedRequirement = useAppSelector(selectedTripRequirement);

    const req = useMemo(() => {
        return requirement || selectedRequirement;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement?.id, selectedRequirement?.id]);

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const descriptionWatch = watch('description');
    const dispatch = useAppDispatch();
    const handleSubmitForm = async (data: any) => {
        const { description, sensitiveInformation } = data;

        if (!tempFiles?.length && !description?.length) {
            setError("files", {
                type: "custom",
                message: "You must upload a file or complete the text info",
            });
            return;
        }

        const files = tempFiles.map((file) => file.path);
        await dispatch(
            addTripRequirementFile(
                Number(tripId),
                Number(req.id),
                description || "",
                files,
                userEmail,
                sensitiveInformation
            )
        );
        onSubmit();
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: number, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const onSelectFiles = (e: any) => {
        clearErrors("files");
        if (e.target.files) {
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: new Date().valueOf(),
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setFilesProgress((prevArray) => {
                    return [...prevArray, newtempFile];
                });

                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    removeProgressFile(newtempFile.id);
                    setTempFiles((prev) => {
                        return [
                            ...prev,
                            {
                                file: file,
                                path: response,
                                id: newtempFile.id,
                            },
                        ];
                    });
                });
            });
            return;
        }
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([...deletedFilesState, filesPreviews[index].name]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    return (
        <div className="m-4">
            <div className="flex flex-wrap">
                <div className="text-left font-semibold text-lg flex-grow text-gray-600">
                    Submit requirement
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="flex flex-col flex-grow"
            >
                <div className="gap-x-12 flex-wrap mt-4 flex-grow">
                    <>
                        <div className={"!py-2"}>
                            <DropFileInput
                                iconSrc={fileImage}
                                iconClass={"w-16"}
                                multiple={true}
                                onChange={onSelectFiles}
                            />
                            {!!errors["files"]?.message && (
                                <InputErrorLabel
                                    error={errors["files"]?.message}
                                />
                            )}
                        </div>
                        {filesProgress.map((file) => {
                            return (
                                <ProgressBar
                                    key={`fileProgress_${
                                        file.id || file?.file?.name
                                    }`}
                                    progress={file.progress}
                                    name={file.file.name}
                                    onRemove={() =>
                                        removeProgressFile(file.id, true)
                                    }
                                />
                            );
                        })}
                        <div className="flex flex-wrap gap-4 mt-4">
                            {tempFiles.map((file) => {
                                return (
                                    <FilePreview
                                        key={`filePrev_${
                                            file.id || file?.file?.name
                                        }`}
                                        onRemove={() => {
                                            removeTempFile(file.id);
                                        }}
                                        name={file?.file?.name}
                                    />
                                );
                            })}
                            {filesPreviews.map((file, index) => {
                                return (
                                    <FilePreview
                                        key={`file_${file.name}`}
                                        onRemove={() => {
                                            handleFileRemove(index);
                                        }}
                                        name={file.name}
                                    />
                                );
                            })}
                        </div>
                    </>

                    <div className="text-left break-words mt-2">
                        <TextareaWithValid
                            register={register}
                            label={"Info"}
                            id="description"
                            name="description"
                            error={errors["description"]?.message}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-3 text-left mb-4">
                        <label className="flex items-center space-x-3">
                            <CheckBox
                                name="sensitiveInformation"
                                id="sensitiveInformation"
                                control={control}
                                label="Delete my requirements after the trip ends."
                            />
                        </label>
                    </div>
                </div>
                <div className="flex gap-4 md:justify-end px-4 pt-24 text-right sm:px-6">
                    <Button
                        disabled={
                            !tempFiles?.length && !descriptionWatch?.length
                        }
                        type="submit"
                        label="Save"
                    />
                </div>
            </form>
        </div>
    );
}
