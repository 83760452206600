import { MouseEventHandler } from "react";
import Modal, { Modal_Size } from ".";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import Loader from "../Loader";
import { Paragraph } from "../Paragraph/Paragraph";

export interface ModalProps {
    open?: boolean;
    hideCloseButton?: boolean;
    isLoading?: boolean;
    title?: string;
    description?: string;
    okButtonText?: string;
    cancelButtonText?: string;
    handleCancel?: MouseEventHandler<HTMLButtonElement>;
    handleSubmit?: MouseEventHandler<HTMLButtonElement>;
    handleClose?: MouseEventHandler<HTMLButtonElement>;
    size?: string;
    okButtonsClass?: string;
    cancelButtonsClass?: string;
    notes?: string;
    className?: string;
}

const ModalConfirmation: React.FunctionComponent<ModalProps> = (props) => {
    const {
        open,
        title,
        description,
        handleCancel,
        handleClose,
        handleSubmit,
        okButtonText,
        cancelButtonText,
        isLoading,
        hideCloseButton,
        size,
        notes,
        okButtonsClass,
        cancelButtonsClass,
        className,
    } = props;
    return (
        <Modal
            open={open}
            onClose={handleClose || handleCancel}
            hideCloseButton={hideCloseButton}
            size={size || Modal_Size.xs}
            className={className}
        >
            <div className="px-6">
                <div className="text-lg font-semibold text-neutral-400 justify-center pb-4 text-center">
                    {title}
                </div>
                {!!description?.length && (
                    <Paragraph className="text-base font-base justify-center text-center">
                        {description}
                    </Paragraph>
                )}
                {!isLoading && (
                    <div className="flex flex-grow items-center">
                        <div className="flex flex-grow flex-row gap-4 lg:px-4 mt-8 justify-center text-right">
                            {!!handleCancel && (
                                <ButtonSquared
                                    outlined={true}
                                    label={cancelButtonText || "Cancel"}
                                    onClick={handleCancel}
                                    size={ButtonSize.SMALL}
                                    className={cancelButtonsClass}
                                />
                            )}

                            {!!handleSubmit && (
                                <ButtonSquared
                                    label={okButtonText || "Remove"}
                                    onClick={handleSubmit}
                                    size={ButtonSize.SMALL}
                                    className={okButtonsClass}
                                />
                            )}
                        </div>
                    </div>
                )}
                {notes?.length && (
                    <div className="items-center text-center text-xs text-neutral-700 my-8">
                        {notes}
                    </div>
                )}
                {!!isLoading && (
                    <div className="w-fit m-auto py-4">
                        <Loader />
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default ModalConfirmation;
