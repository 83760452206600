import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useTripAccessControl from "../../../hooks/useAccessControl";
import {
    getProfileFull,
    selectProfile,
} from "../../authentication/userSlice";
import {
    getTrip,
    selectError,
    setError,
    setSelectedTrip,
} from "../tripSlice";
import {
    sendToast,
} from "../../../helpers";
import {
    TOAST_TYPE,
} from "../../../constants";

export default function TripHome() {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const error = useAppSelector(selectError);
    let { tripId } = useParams();
    const navigate = useNavigate();
    const { allowed } = useTripAccessControl();
    useEffect(() => {
        if (error) {
            let errorText = "Couldn't retrieve the trip data.";
            switch (error) {
                case 400:
                    errorText =
                        "You are not part of this trip. Please contact the host if you'd like to join this trip.";
                    break;
                case 404:
                    errorText =
                        "The requested trip does not exist or it was deleted by the Primary Admin.";
                    break;

                default:
                    break;
            }
            sendToast(TOAST_TYPE.WARNING, errorText);
            navigate("/trips");
        }

        return () => {
            dispatch(setError({ error: false }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (tripId && profile?.email) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(setSelectedTrip({ selectedTrip: null }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, profile?.email]);


    const fetchData = () => {
        dispatch(getProfileFull());
        if (tripId) {
            dispatch(getTrip(Number(tripId), true, false));
        }
    };

    return <>{allowed ? <Outlet /> : <Navigate to="/trips" />}</>;
}
