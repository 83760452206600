import moment from "moment";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AddUsersForm from "../../../components/AddUsersForm";
import ButtonSquared from "../../../components/ButtonSquare";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import { ButtonSize } from "../../../constants";
import InsightDetails from "../insightDetails/insightDetails";
import {
    downloadInsightPDF,
    getInsights,
    selectInsights,
    shareInsight,
} from "../insightsSlice";
import Insights from "./components/Insights";
import { DATE_FORMAT } from "../../../helpers";
import { useNavigate } from "react-router";

const normalizeInsight = (insight: any) => {
    const activity =
        insight.activity?.hotelActivity?.hotel ||
        insight.activity?.otherActivity?.other ||
        insight.activity?.restaurantActivity?.restaurant;
    return {
        id: insight.id,
        type: insight?.activity?.type,
        title: insight.activity?.title || activity.name,
        date: moment(insight.activity?.startDate).format(DATE_FORMAT),
        location: `${activity?.city}, ${activity?.country}`,
        trip: insight?.activity?.trip?.title,
    };
};

const DotMenuKeys = {
    share: "SHARE",
    details: "details",
    export: "EXPORT",
};
const DotMenuOptions: any = [
    {
        icon: "ShareIcon",
        label: "Share Insight",
        key: DotMenuKeys.share,
    },
    {
        icon: "DownloadIcon",
        label: "Export PDF",
        key: DotMenuKeys.export,
    },
];

export default function MyInsightsList(props: any) {
    const dispatch = useAppDispatch();
    const [selectedInsight, setSelectedInsight] = useState<any>();
    const insights = useAppSelector(selectInsights);
    const [addUserModalOpen, setAddUserOrgModalOpen] = useState(false);
    const [viewInsightDetails, setViewInsightDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userErrorModalOpen, setUserErrorModalOpen] = useState(false);
    const [errorUsers, setErrorUsers] = useState("");
    const insightsListRef: any = useRef();
    const navigate = useNavigate();
    const getData = (filters: any) => {
        dispatch(
            getInsights(
                filters.take,
                filters.skip,
                filters.type,
                filters.search,
                filters.startDate,
                filters.endDate
            )
        );
    };

    const handleClickDotsMenu = (option: any, insight: any) => {
        switch (option) {
            case DotMenuKeys.share:
                setSelectedInsight(insight);
                handleAddUser();
                break;
            case DotMenuKeys.export:
                dispatch(downloadInsightPDF(insight.id));
                break;
            case DotMenuKeys.details:
                setSelectedInsight(insight);
                setViewInsightDetails(true);
                break;
            default:
                break;
        }
    };

    const handleAddUser = () => {
        setAddUserOrgModalOpen(true);
    };

    const handleModalClose = () => {
        setAddUserOrgModalOpen(false);
    };

    const handleFinishAdd = async (users: any) => {
        setLoading(true);
        const errorArray: string[] = await shareInsight(
            selectedInsight.id!,
            users.map((u: { email: string }) => u.email)
        );
        setLoading(false);
        handleModalClose();
        if (!!errorArray?.length) {
            setErrorUsers(errorArray.join(", \n"));
            setUserErrorModalOpen(true);
        }
    };

    const openInsight = (insightId: any) => {
        const insight = insights.data.find((i) => i.id === insightId);
        const activityType = insight?.activity?.type.toLowerCase();
        navigate(
            `/trips/${insight?.activity?.trip?.id}/itinerary/${insight?.activity?.id}/add-private-${activityType}-insight`
        );
    };

    return (
        <>
            <Modal
                open={viewInsightDetails}
                onClose={() => {
                    setViewInsightDetails(false);
                }}
            >
                <InsightDetails insight={selectedInsight} />
            </Modal>
            <Modal open={addUserModalOpen} onClose={handleModalClose}>
                {!loading && (
                    <AddUsersForm
                        title={"Share with"}
                        formType="organization"
                        handleClose={handleModalClose}
                        handleFinishAdd={handleFinishAdd}
                        hideRole={true}
                        disclaimer={
                            "*By adding members, they will automatically have access to your public reviews and private insights, these users can remove form their list or export to PDF doc. Please be sure you are ready for them to get the access before you add them here."
                        }
                    />
                )}
                {!!loading && (
                    <div className="w-fit m-auto">
                        <Loader />
                    </div>
                )}
            </Modal>
            <Modal
                open={userErrorModalOpen}
                onClose={() => {
                    setUserErrorModalOpen(false);
                }}
            >
                <div className="w-full px-4 py-4 flex flex-col">
                    <h1 className="text-neutral-900 text-lg font-semibold">
                        Couldn't share insight with:
                    </h1>
                    <div>{errorUsers}</div>

                    <div className="w-full justify-end text-right flex-end">
                        <ButtonSquared
                            size={ButtonSize.SMALL}
                            outlined
                            label="Ok"
                            type="button"
                            onClick={() => {
                                setUserErrorModalOpen(false);
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Insights
                onInsightClick={(insight: any) => {
                    openInsight(insight?.id);
                }}
                insights={insights}
                ref={insightsListRef}
                handleClickDotsMenu={handleClickDotsMenu}
                fetchData={getData}
                DotMenuOptions={DotMenuOptions}
                insightsNormalizer={normalizeInsight}
            />
        </>
    );
}
