import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import Input from "../../components/Input/Input";
import { duplicateTrip, getTrip, selectedTrip } from "./tripSlice";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PAGE_SECTIONS, TOAST_TYPE, TripType } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePickerMaterial from "../../components/DatePickerMaterial";
import { setCurrentSection } from "../layout/layoutSlice";
import moment from "moment";
import { sendToast } from "../../helpers";
import InputSwitch from "../../components/InputSwitch/InputSwitch";

const schema = yup
    .object({
        title: yup.string().required("This field is required"),
        startDate: yup.date().required("This field is required"),
        itinerary: yup.boolean(),
        files: yup.boolean(),
        documents: yup.boolean(),
        images: yup.boolean(),
    })
    .required("This field is required");

export default function DuplicateTripForm() {
    const [startDate, setStartDate] = useState<any>(new Date());
    const [copyValues, setCopyValues] = useState<any>({
        itinerary: true,
        files: false,
        documents: false,
        images: false,
    });

    const navigate = useNavigate();
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    let { tripId } = useParams();

    useEffect(() => {
        if (tripId) {
            if (!trip || Number(trip.id) !== Number(tripId))
                dispatch(getTrip(Number(tripId)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, trip]);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_EDIT })
        );
        reset({
            title: trip?.title,
            startDate: moment(moment.now()).startOf("day").toDate(),
        });
        setStartDate(moment(moment.now()).startOf("day").toDate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, trip]);

    const handleSubmitForm = async (data: any) => {
        const payload = {
            startDate: moment(data.startDate).startOf("day").toDate(),
            title: data.title,
            itinerary: copyValues.itinerary,
            files: copyValues.files,
            documents: copyValues.documents,
            images: copyValues.images,
        };
        const newTrip = await dispatch(duplicateTrip(trip.id, payload));
        if (newTrip) {
            navigate(`/trips/${newTrip.id}`);
        } else {
            sendToast(TOAST_TYPE.ERROR, "Something went wrong");
        }
    };

    const handleSwitchChange = (e: any) => {
        setCopyValues({ ...copyValues, [e.target.name]: !!e.target.checked });
    };

    const goBack = () => {
        navigate(`/trips/${tripId}`);
    };

    return (
        <div>
            <div className="flex flex-wrap mb-12">
                <div className="text-left font-semibold text-lg flex-grow">
                    Duplicate Trip
                </div>
            </div>

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-12 lg:mx-24">
                    <div className="col-span-6 lg:col-span-3">
                        <Input
                            {...register("title", { required: true })}
                            label="Name"
                            type="text"
                            id="title"
                            name="title"
                            error={errors["title"]?.message}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-3 text-left">
                        <DatePickerMaterial
                            control={control}
                            minDate={moment(moment.now())
                                .startOf("day")
                                .toDate()}
                            name="startDate"
                            value={startDate}
                            id="startDate"
                            label="Start date"
                            onChange={setStartDate}
                            error={errors["startDate"]?.message}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-3 text-left">
                        <div className="flex max-w-xs">
                            <div className="font-semibold text-base text-left text-gray-600 flex-grow">
                                Itinerary
                            </div>
                            <InputSwitch
                                id="itinerary"
                                name="itinerary"
                                defaultChecked={true}
                                onClick={handleSwitchChange}
                            />
                        </div>
                    </div>
                    {trip?.type === TripType.ORGANIZER && (
                        <>
                            <div className="col-span-6 lg:col-span-3 text-left">
                                <div className="flex max-w-xs">
                                    <div className="font-semibold text-base text-left text-gray-600 flex-grow">
                                        Files
                                    </div>
                                    <InputSwitch
                                        id="files"
                                        name="files"
                                        defaultChecked={copyValues.files}
                                        onClick={handleSwitchChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-6 lg:col-span-3 text-left">
                                <div className="flex max-w-xs">
                                    <div className="font-semibold text-base text-left text-gray-600 flex-grow">
                                        Requirements
                                    </div>
                                    <InputSwitch
                                        id="documents"
                                        name="documents"
                                        defaultChecked={copyValues.documents}
                                        onClick={handleSwitchChange}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-span-6 lg:col-span-3 text-left">
                        <div className="flex max-w-xs">
                            <div className="font-semibold text-base text-left text-gray-600 flex-grow">
                                Image Gallery
                            </div>
                            <InputSwitch
                                id="images"
                                name="images"
                                defaultChecked={copyValues.images}
                                onClick={handleSwitchChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="px-4 pt-24 text-right">
                    <span className="mr-6">
                        <Button onClick={goBack} outlined label="Cancel" />
                    </span>

                    <Button type="submit" label="Duplicate" />
                </div>
            </form>
        </div>
    );
}
