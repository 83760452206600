import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError } from "../authentication/userSlice";
import { UserTypeVariant } from "../../components/UserType/UserType";
import { PlanType, TOAST_TYPE } from "../../constants";
import {
    currentPlan,
    IPaymentMethods,
    paymentIntents,
} from "../../types/plan.type";
import { PaymentMethod } from "@stripe/stripe-js";
import { sendToast } from "../../helpers";

export interface PlansState {
    isLoading: boolean;
    plans: Array<{
        id: string;
        name: string;
        link: string;
        subscriptionId: string;
    }>;
    selectedPlan: any | null;
    error: string;
    currentPlan: currentPlan | null;
    currentPlanOrganization: currentPlan | null;
    paymentIntents: any;
    subscriptions: any[];
    paymentMethods: IPaymentMethods[];
}

const initialState: PlansState = {
    isLoading: false,
    plans: [],
    selectedPlan: null,
    error: "",
    currentPlan: null,
    currentPlanOrganization: null,
    paymentIntents: {},
    subscriptions: [],
    paymentMethods: [],
};

export const plansSlice = createSlice({
    name: "plans",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setPlans: (state, action: PayloadAction<{ plans: any }>) => {
            if (Array.isArray(action.payload)) {
                state.plans = [...action.payload];
            } else {
                console.error("Error: action.payload.plans is not an array");
            }
        },
        _setSelectedPlan: (state, action: PayloadAction<number>) => {
            state.selectedPlan = action.payload;
        },
        setCurrentPlan: (state, action: PayloadAction<currentPlan | null>) => {
            state.currentPlan = action.payload;
        },
        setCurrentPlanOrganization: (
            state,
            action: PayloadAction<currentPlan | null>
        ) => {
            state.currentPlanOrganization = action.payload;
        },
        setPaymentIntents: (state, action: PayloadAction<paymentIntents[]>) => {
            state.paymentIntents = action.payload;
        },
        setSubscriptions: (state, action: PayloadAction<any[]>) => {
            state.subscriptions = action.payload;
        },
        setPaymentMethods: (state, action: PayloadAction<IPaymentMethods[]>) => {
            state.paymentMethods = action.payload;
        },
    },
});

export const {
    isLoading,
    setPlans,
    _setSelectedPlan,
    setError,
    setCurrentPlan,
    setCurrentPlanOrganization,
    setPaymentIntents,
    setSubscriptions,
    setPaymentMethods,
} = plansSlice.actions;
export const selectPlans = (state: RootState) => state.plans.plans;
export const selectSelectedPlan = (state: RootState) =>
    state.plans.selectedPlan;
export const selectCurrentPlan = (state: RootState) => state.plans.currentPlan;
export const selectPaymentIntents = (state: RootState) =>
    state.plans.paymentIntents;
export const selectPaymentMethods = (state: RootState) => state.plans.paymentMethods;
export const isLoadingData = (state: RootState) => state.plans.isLoading;

export const getUserPaymentData = (): AppThunk => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        const { data } = (await serverApi.getUserPayment()) || {};
        if (data.length > 0) {
            dispatch(_setSelectedPlan(data[0].planId));
        }
        dispatch(isLoading(false));
    } catch (error) {
        dispatch(isLoading(false));
        dispatch(handleError(error));
    }
};

export const cancelPaymentUser = (): AppThunk => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        await serverApi.cancelPaymentUser();
        dispatch(_setSelectedPlan(0));
        dispatch(isLoading(false));
    } catch (error) {
        dispatch(isLoading(false));
        dispatch(handleError(error));
    }
};

export const createPlan =
    (plan: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.createPlan(plan);
            dispatch(_setSelectedPlan(response.data.id));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getPlan =
    (planId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getPlan(planId);
            dispatch(_setSelectedPlan(response.data.id));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const selectPlanDetails = (state: RootState) => {
    const selectedPlan = state.plans.selectedPlan;
    if (selectedPlan !== null) {
        return state.plans.plans.find((plan) => plan.id === selectedPlan);
    }
    return null;
};

export const clearPlan = () => (dispatch: any) => {
    dispatch(_setSelectedPlan(0));
};

export const getCurrentPlan =
    (useLoader = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            useLoader && dispatch(isLoading(true));
            const response = await serverApi.getCurrentPlan();
            let data =
                (response?.data && {
                    ...response.data,
                    ...(response.data?.plan?.data?.myPlanData || {}),
                }) ||
                {};
            dispatch(setCurrentPlan(data));
            useLoader && dispatch(isLoading(false));
        } catch (error) {
            useLoader && dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getPlanOrganization =
    (organizationId: Number, useLoader = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            useLoader && dispatch(isLoading(true));
            const response = await serverApi.getPlanByOrganization(
                organizationId
            );
            let data = response?.data || {};
            dispatch(setCurrentPlanOrganization(data));
            useLoader && dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const cancelCurrentPlan = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        dispatch(isLoading(true));
        const currentPlan = state.plans.currentPlan;
        const payload = {
            subscriptionId: currentPlan?.subscriptionId,
            stripeSubscriptionId: currentPlan?.stripeSubscriptionId,
        };
        await serverApi.cancelCurrentPlan(payload);
        dispatch(getCurrentPlan(false));
        dispatch(isLoading(false));
    } catch (error) {
        dispatch(isLoading(false));
        dispatch(handleError(error));
    }
};

export const getAvailablePlans =
    (planType: PlanType, userType?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const { data } =
                (await serverApi.getAvailablePlans(
                    planType,
                    `${userType || UserTypeVariant.SUPPLIER}`
                )) || {};
            dispatch(setPlans(data));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const fetchPlans =
    (
        planType: PlanType,
        organizationId?: number, // only for ORGANIZATION plan type
        userType?: string,
        useLoader: boolean = true
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            useLoader && dispatch(isLoading(true));
            switch (planType) {
                case PlanType.ORGANIZATION:
                    if (!organizationId)
                        throw new Error(
                            "Organization ID is required for ORGANIZATION plan type"
                        );
                    const orgResponse = await serverApi.getPlanByOrganization(
                        organizationId
                    );
                    dispatch(
                        setCurrentPlanOrganization(orgResponse.data || {})
                    );
                    break;
                case PlanType.USER:
                    const userResponse = await serverApi.getAvailablePlans(
                        PlanType.USER,
                        `${userType || UserTypeVariant.SUPPLIER}`
                    );
                    dispatch(setPlans(userResponse.data || {}));
                    break;
                case PlanType.HOTEL:
                    const hotelResponse = await serverApi.getAvailablePlans(
                        PlanType.HOTEL,
                        `${userType || UserTypeVariant.SUPPLIER}`
                    );
                    dispatch(setPlans(hotelResponse.data || {}));
                    break;
                default:
                    throw new Error("Unsupported plan type");
            }
            useLoader && dispatch(isLoading(false));
        } catch (error) {
            useLoader && dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const fetchPaymentsByEmail =
    (email: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getPaymentsHistory();
            dispatch(setPaymentIntents(response.data));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getPaymentMethods =
    (): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getPaymentMethods();
            if (response.data.length > 0) {
                const formattedData: IPaymentMethods[] = response.data.map((item: any) => {
                    return {
                        id: item.id,
                        object: item.object,
                        billing_details: item.billing_details,
                        card: item.card,
                        created: item.created,
                        customer: item.customer,
                        livemode: item.livemode,
                        metadata: item.metadata,
                        radar_options: item.radar_options,
                        type: item.type,
                        
                    };
                });

                dispatch(setPaymentMethods(formattedData));
            } else {
                dispatch(setPaymentMethods([]));
            }
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

    export const createPaymentMethod =
    (email: string, paymentMethodObj: PaymentMethod): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addPaymentMethod(
                email,
                paymentMethodObj
            );
            dispatch(setPaymentIntents(response.data.paymentIntents));
            dispatch(isLoading(false));
        } catch (error: any) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return error.response?.data?.error || "An unexpected error occurred.";
        }
    };


export const payUserSubscription =
    (
        email: string,
        paymentMethodId: string,
        priceId: string,
        planId: number,
        userId: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.payUserSubscription(
                email,
                paymentMethodId,
                priceId,
                planId,
                userId
            );
            dispatch(isLoading(false));
            return response;
        } catch (error: any) {
            console.log("Error", error);
            dispatch(isLoading(false));
            throw error;
        }
    };

export default plansSlice.reducer;
