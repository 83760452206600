import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    blockUnblockUserFromTrip,
    copyTripUserInvitationLink,
    removeUserFromTrip,
    resendTripUserInvitation,
    selectedTrip,
    selectedTripUsers,
    selectMeOnTrip,
    updateTripOwner,
} from "../../tripSlice";
import {
    PAGE_SECTIONS,
    TripUserRoleOptionsWithoutOwner,
    TRIP_USERS_LIST_OPTIONS_KEYS,
    UserRoleOnTrip,
    UserStatusOnTrip,
    TripType,
    TripUserRoleOptions,
    ButtonSize,
} from "../../../../constants";
import { useEffect, useState } from "react";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../layout/layoutSlice";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";
import { useNavigate } from "react-router";
import { selectProfile } from "../../../authentication/userSlice";
import TripUsersTable from "./TripUsersTable";
import TripUsersList from "./TripUsersList";
import Modal from "../../../../components/Modal";
import SelectInput from "../../../../components/InputSelect/SelectInput";
import ButtonSquared from "../../../../components/ButtonSquare";

export default function TripUsers({
    handlePaginatorChange,
    handleUserRoleChange,
    updateData,
}: any) {
    const navigate = useNavigate();
    const tripUsers = useAppSelector(selectedTripUsers);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
    const [changeOwnerConfirmationModal, setChangeOwnerConfirmationModal] =
        useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [deleteUserEmail, setDeleteUserEmail] = useState<string>("");
    const profile = useAppSelector(selectProfile);
    const meOnTrip = useAppSelector(selectMeOnTrip(profile?.email));
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const [newRole, setNewRole] = useState<any>(null);
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_PARTICIPANTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveUserFromTrip = async (email: string) => {
        setDeleteUserEmail(email);
        setDeleteUserModalOpen(true);
    };

    const sendRemoveUserFromTrip = async () => {
        await dispatch(removeUserFromTrip(Number(trip?.id), selectedUser));
        setDeleteUserModalOpen(false);
    };

    const changeTripOwner = async () => {
        await dispatch(
            updateTripOwner(Number(trip?.id), selectedUser?.userEmail)
        );
        updateData && updateData();
        setChangeRoleModalOpen(false);
        setChangeOwnerConfirmationModal(false);
    };

    const handleUserClick = (user: any) => {
        user?.user?.firstName
            ? handleOpenProfile(user?.user?.id)
            : handleOpenNonRegisteredProfile(user?.userEmail);
    };

    const handleOpenProfile = (id: number) => {
        navigate(`/trips/${trip.id}/users/${encodeURIComponent(id)}`);
    };

    const handleOpenNonRegisteredProfile = (email: number) => {
        navigate(`/trips/${trip.id}/nonuser/${encodeURIComponent(email)}`);
    };

    const handleClickUserDotsMenu = (optionId: string, user: any) => {
        setSelectedUser(user);
        setNewRole(user?.role);
        switch (optionId) {
            case TRIP_USERS_LIST_OPTIONS_KEYS.REMOVE_USER:
                handleRemoveUserFromTrip(user?.userEmail);
                break;
            case TRIP_USERS_LIST_OPTIONS_KEYS.RESEND_INVITE:
                handleResendInvite(user?.userEmail);
                break;
            case TRIP_USERS_LIST_OPTIONS_KEYS.COPY_INVITE_LINK:
                dispatch(copyTripUserInvitationLink());
                break;
            case TRIP_USERS_LIST_OPTIONS_KEYS.BLOCK_USER:
                dispatch(
                    blockUnblockUserFromTrip(
                        Number(trip?.id),
                        user?.userEmail,
                        UserStatusOnTrip.BLOCKED
                    )
                );
                break;
            case TRIP_USERS_LIST_OPTIONS_KEYS.UNBLOCK_USER:
                dispatch(
                    blockUnblockUserFromTrip(
                        Number(trip.id),
                        user?.userEmail,
                        UserStatusOnTrip.CONFIRMED
                    )
                );
                break;
            case TRIP_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE:
                setChangeRoleModalOpen(true);
                break;
            default:
                break;
        }
        return;
    };

    const handleResendInvite = (userEmail: string) => {
        dispatch(resendTripUserInvitation(trip?.id, userEmail));
    };

    const showRoleSelectInput = (user: any, canEdit: boolean) => {
        const isOwner = user?.role === UserRoleOnTrip.OWNER;
        const isMe = user?.userEmail === meOnTrip?.userEmail;
        const canBeModified =
            trip?.canEdit && user?.status === UserStatusOnTrip.CONFIRMED;
        return (
            (!isMe && !isOwner && trip?.canEdit && canBeModified) ||
            profile?.isAdmin
        );
    };

    return (
        <>
            <ModalConfirmation
                open={deleteUserModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${deleteUserEmail}`}
                handleCancel={() => setDeleteUserModalOpen(false)}
                handleSubmit={sendRemoveUserFromTrip}
            />
            <ModalConfirmation
                open={changeOwnerConfirmationModal}
                title="Are you sure?"
                description={`You are about to transfer the primary administration of this trip to ${selectedUser?.userEmail}. Your role will be changed to Admin.`}
                handleCancel={() => setChangeOwnerConfirmationModal(false)}
                handleSubmit={changeTripOwner}
                okButtonText={"Confirm"}
            />
            <Modal
                open={changeRoleModalOpen}
                onClose={() => setChangeRoleModalOpen(false)}
            >
                <div className="flex flex-col mb-2">
                    <div className="font-semibold">Change User Role</div>
                    <div>{selectedUser?.userEmail}</div>
                    {selectedUser && (
                        <SelectInput
                            onChange={(value: any) => {
                                if (value === UserRoleOnTrip.OWNER) {
                                    setChangeOwnerConfirmationModal(true);
                                } else {
                                    setChangeRoleModalOpen(false);
                                    return handleUserRoleChange(
                                        value,
                                        selectedUser?.userEmail
                                    );
                                }
                            }}
                            defaultValue={selectedUser?.role}
                            options={
                                (meOnTrip?.role === UserRoleOnTrip.OWNER ||
                                    profile?.isAdmin) &&
                                selectedUser?.role === UserRoleOnTrip.ADMIN
                                    ? TripUserRoleOptions
                                    : TripUserRoleOptionsWithoutOwner
                            }
                        />
                    )}
                </div>
            </Modal>
            <Modal
                hideCloseButton
                open={changeRoleModalOpen}
                onClose={() => setChangeRoleModalOpen(false)}
            >
                <div className="flex flex-col m-2">
                    <div className="font-semibold mb-2">Change role</div>
                    <div className="text-neutral-600 text-sm font-semibold">
                        Choose a new role for this member.
                    </div>
                    <div className="flex flex-row w-full items-center">
                        <div className="text-neutral-900 text-sm font-semibold flex-1">
                            {selectedUser?.user?.firstName}{" "}
                            {selectedUser?.user?.lastName}
                        </div>
                        {selectedUser && (
                            <SelectInput
                                onChange={(value: any) => {
                                    setNewRole(value);
                                }}
                                defaultValue={newRole}
                                options={
                                    (meOnTrip?.role === UserRoleOnTrip.OWNER ||
                                        profile?.isAdmin) &&
                                    selectedUser?.role === UserRoleOnTrip.ADMIN
                                        ? TripUserRoleOptions
                                        : TripUserRoleOptionsWithoutOwner
                                }
                            />
                        )}
                    </div>

                    <div className="text-neutral-500 text-sm font-semibold">
                        {selectedUser?.userEmail}
                    </div>

                    <div className="flex flx-row gap-4 justify-end mt-4">
                        <ButtonSquared
                            outlined
                            size={ButtonSize.SMALL}
                            label={"Cancel"}
                            onClick={() => setChangeRoleModalOpen(false)}
                        />
                        <ButtonSquared
                            label={"Confirm"}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                if (newRole === UserRoleOnTrip.OWNER) {
                                    setChangeOwnerConfirmationModal(true);
                                } else {
                                    setChangeRoleModalOpen(false);
                                    return handleUserRoleChange(
                                        newRole,
                                        selectedUser?.userEmail
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </Modal>
            {isDesktop ? (
                <TripUsersTable
                    showRoleSelectInput={showRoleSelectInput}
                    showDocuments={trip?.type === TripType.ORGANIZER}
                    tripUsers={tripUsers}
                    canEdit={trip?.canEdit || profile?.isAdmin}
                    meOnTrip={meOnTrip || profile?.isAdmin}
                    handleClickUserDotsMenu={handleClickUserDotsMenu}
                    handlePaginatorChange={handlePaginatorChange}
                    handleUserClick={handleUserClick}
                    handleUserRoleChange={(
                        role: UserRoleOnTrip,
                        email: string
                    ) => {
                        setChangeRoleModalOpen(false);
                        handleUserRoleChange(role, email);
                    }}
                    isAdmin={profile?.isAdmin}
                />
            ) : (
                <TripUsersList
                    tripUsers={tripUsers}
                    canEdit={trip?.canEdit || profile?.isAdmin}
                    meOnTrip={meOnTrip || profile?.isAdmin}
                    handleClickUserDotsMenu={handleClickUserDotsMenu}
                    handlePaginatorChange={handlePaginatorChange}
                    handleUserClick={handleUserClick}
                    isAdmin={profile?.isAdmin}
                />
            )}
        </>
    );
}
