import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckIcon } from "@heroicons/react/solid";
import ButtonSquared from "../../components/ButtonSquare";
import { BubblePlan } from "../../components/BubblePlan/BubblePlan";
import { ListOption } from "../../components/ListOption/ListOption";
import { CancelPlanModal } from "../../components/Modal/CancelPlanModal";
import { cancelCurrentPlan } from "./plansSlice";
import { ChoosePlanModal } from "../../components/Modal/ChoosePlanModal";
import { ButtonSize } from "../../constants";
import { RootState } from "../../app/store";

export const CurrentPlan = () => {
    const dispatch = useDispatch();
    const plan = useSelector((state: RootState) => state.plans.currentPlan);
    const user = useSelector((state: RootState) => state.user.profile);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [showChangePlan, setShowChangePlan] = useState(false);

    const OrangeDotIcon = (
        <div className="text-white rounded-full w-2 h-2 bg-orange-600 ml-2.5 mt-1"></div>
    );

    const DoneIcon = <CheckIcon width={24} className="text-orange-500" />;

    const handleCancel = () => {
        setOpenCancelModal(true);
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleCancelSubscription = () => {
        dispatch(cancelCurrentPlan());
        setOpenCancelModal(false);
    };

    const handleClickChangePlan = () => {
        setShowChangePlan(true);
    };

    const handleClickCloseChangePlan = () => {
        setShowChangePlan(false);
    };

    return (
        <div className="flex flex-col items-center gap-6 place-content-center">
            <CancelPlanModal
                open={openCancelModal}
                handleCancel={handleCloseCancelModal}
                handleSubmit={handleCancelSubscription}
                plan={plan}
            />
            <ChoosePlanModal
                open={showChangePlan}
                handleClose={handleClickCloseChangePlan}
                onChangePlan={handleClickCloseChangePlan}
                userType={user?.userType}
                forceCloseModal={() => setShowChangePlan(false)}
            />
            <div className="text-left bg-white rounded-xl p-6 w-full lg:w-3/5">
                <div className="w-full flex flex-row gap-4 justify-between">
                    <span className="font-semibold">Actual Plan</span>
                </div>
                <BubblePlan plan={plan} />
                <ButtonSquared
                    type="button"
                    outlined={false}
                    onClick={handleClickChangePlan}
                    label="Choose your Plan"
                    className="px-4 whitespace-nowrap"
                    size={ButtonSize.FIT}
                />

                {plan?.subscriptionStatus !== "trialing" && (
                    <div className="flex flex-row justify-between my-6">
                        <span className="font-semibold">
                            {plan?.billingStartDate
                                ? "Billing start date"
                                : "Subscription Plans"}
                        </span>
                        {!!plan?.billingStartDate && (
                            <span className="font-semibold">
                                {plan?.billingStartDate}
                            </span>
                        )}
                    </div>
                )}
                <ListOption
                    options={plan?.planBenefits || []}
                    icon={DoneIcon}
                />
                <ListOption
                    options={plan?.planConditions || []}
                    icon={OrangeDotIcon}
                />
                <div className="flex flex-col">
                    {plan?.disclaimers?.map(
                        (disclaimer: string, index: number) => {
                            return (
                                <div
                                    className="text-xs px-2 mb-1 text-neutral-400"
                                    key={`${index}-${disclaimer}`}
                                >
                                    {disclaimer}
                                </div>
                            );
                        }
                    )}
                </div>

                {!!(
                    plan?.subscriptionId &&
                    plan?.subscriptionStatus !== "canceled"
                ) && (
                    <div className="flex w-full flex-row items-end mt-6 justify-end">
                        <ButtonSquared
                            type="button"
                            outlined
                            onClick={handleCancel}
                            label="Cancel Subscription"
                            className="px-4 whitespace-nowrap"
                            size={ButtonSize.FIT}
                        />
                    </div>
                )}
                {!plan?.subscriptionId && (
                    <div className="text-sm font-normal mt-6 whitespace-nowrap">
                        Learn more about our plans{" "}
                        <a
                            href="https://famguru.app/pricing"
                            target="_blank"
                            rel="noreferrer"
                            className="underline-offset-4 text-sm font-semibold mb-6 whitespace-nowrap text-orange-500"
                        >
                            {"here >"}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};
