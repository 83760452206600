import { useMemo, useState } from "react";
import { ButtonSize } from "../../../constants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CardSelection from "../CardSelection";
import ButtonSquared from "../../ButtonSquare";
import { useAppSelector } from "../../../app/hooks";
import { createPaymentMethod, getCurrentPlan, payUserSubscription, selectPaymentMethods } from "../../../features/plans/plansSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPlan } from "../../../types/plan.type";

interface PaymentFormProps {
    isLoading: boolean;
    selectedPlan: IPlan;
    handleCallbackPayment: (description: string, paymentStatus: string | null) => void;
    isFromAdmin?: boolean;
    user?: any;
}

const schema = yup
    .object({
        cardHolder: yup.string().required("Cardholder name is required"),
        country: yup.string().required("Country is required"),
    })
    .required();

export const PaymentForm = (props: PaymentFormProps) => {
    const { isLoading, selectedPlan, handleCallbackPayment, isFromAdmin, user } = props;
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const currentUserFromState = useSelector((state: any) => state?.user?.profile);
    
    const currentUser = user || currentUserFromState;
    const [showAddNewCard, setShowAddNewCard] = useState(false);
    const [showCardSelection, setShowCardSelection] = useState(false);

    const [selectedCard, setSelectedCard] = useState<string | null>(null);

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            cardHolder: currentUser.firstName,
            country: currentUser.country,
        },
    });

    const onSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }
    
        if (showAddNewCard) {
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement!,
                billing_details: {
                    email: currentUser.email,
                },
            });
    
            if (error) {
                handleCallbackPayment(error.message || '', "failed");
            } else {
                const errorMessage: any = await dispatch(
                    createPaymentMethod(currentUser.email, paymentMethod)
                );
    
                if (errorMessage) {
                    handleCallbackPayment(errorMessage || '', "failed");
                } else {
                    await handlePayment(paymentMethod.id);
                }
            }
        } else if (selectedCard) {
            handlePayment(selectedCard);
        }
    };
    
    const handlePayment = async (paymentMethodId: string) => {
        const priceId = selectedPlan.data.offerData.price_id;
        const planId = selectedPlan.id;
        const userId = currentUser.id;

        try {
            await dispatch(
                payUserSubscription(
                    currentUser.email,
                    paymentMethodId,
                    priceId,
                    planId,
                    userId
                )
            );
            await dispatch(getCurrentPlan());
            handleCallbackPayment('', "success");

        } catch (error: any) {
            handleCallbackPayment(error.message, "failed");
        }
    }; 

    const handleSelectCard = (cardId: string) => {
        setSelectedCard(cardId);
    };

    const handleAddNewCard = () => {
        setShowAddNewCard(true);
    };

    const handleCardSelection = async () => {
        setShowCardSelection(true);
    };

    const renderCardSection = useMemo(() => {

        if (showAddNewCard) {
            return <CardElement />;
        }

        switch (paymentMethods.length > 0) {
            case true:
                if (showCardSelection) {
                    return (
                        <CardSelection
                            onSelectCard={handleSelectCard}
                            onAddNewCard={handleAddNewCard}
                            paymentMethods={paymentMethods}
                            isLoading={isLoading}
                        />
                    );
                }
                return (
                    <div className="flex my-4 gap-1">
                        <ButtonSquared
                            outlined
                            label={"Select card"}
                            onClick={handleCardSelection}
                            className="p-4 min-w-[320px]"
                            size={ButtonSize.NORMAL}
                        />
                        <ButtonSquared
                            outlined
                            label="Add new card"
                            onClick={handleAddNewCard}
                            className="p-4 min-w-[320px]"
                            size={ButtonSize.NORMAL}
                        />
                    </div>
                );
            default:
                return (
                    <ButtonSquared
                        outlined
                        label="Add new card"
                        onClick={handleAddNewCard}
                        className="p-4 min-w-[650px] mb-4"
                        size={ButtonSize.NORMAL}
                    />
                );
        }

    }, [isLoading, paymentMethods, showAddNewCard, showCardSelection]);

    if (isFromAdmin) {
        return <div className="my-2">
            <ButtonSquared
                type="submit"
                label={isLoading ? `Loading` : `Subscribe`}
                className="w-full"
                onClick={() => handlePayment('isFromAdmin')}
                size={ButtonSize.FIT}
                disabled={!errors || isLoading || !stripe || !elements}
            />
        </div>
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        
        <h3 className="text-sm font-bold text-left mb-4">
            Pay with card
        </h3>

        <p className="text-left text-sm font-medium text-gray-700 mb-1">
            Email
        </p>
        <p className="text-left text-sm text-gray-500 mb-4">
            {currentUser.email}
        </p>

        {renderCardSection}

        <p className="text-left text-sm font-medium text-gray-700 mb-1">
            Cardholder name
        </p>
        <input
            type="text"
            {...register("cardHolder")}
            className="mt-1 w-full border-b border-gray-400 mb-3"
            placeholder="John"
        />
        {errors.cardHolder && (
            <p className="text-red-600 text-sm">
                {errors.cardHolder.message}
            </p>
        )}

        <div className="my-2">
            <ButtonSquared
                type="submit"
                label={isLoading ? `Loading` : `Subscribe`}
                className="w-full"
                onClick={onSubmit}
                size={ButtonSize.FIT}
                disabled={!errors || isLoading || !stripe || !elements}
            />
        </div>
    </form>
}