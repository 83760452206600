import Button from "../../../components/ButtonSquare";
import Input from "../../../components/Input";
import TextAreaInput from "../../../components/TextArea/TextArea";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    TOAST_TYPE,
    TRIP_DEFAULT_IMAGES,
    UserStatusOnOrganization,
} from "../../../constants";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import TripImagesInput from "./ImagesGallery";
import { useAppSelector } from "../../../app/hooks";
import { isLoading, selectedTripCustomInvite } from "../tripSlice";
import DatePickerMaterial from "../../../components/DatePickerMaterial";
import { useDispatch, useSelector } from "react-redux";
import { UserTypeVariant } from "../../../components/UserType/UserType";
import SelectInputWithValid from "../../../components/InputSelect";
import { useTripUpdateDatesContext } from "./TripUpdateDatesContext";
import server from "../../../api/server";
import { sendToast } from "../../../helpers";
import { useConstantsContext } from "../../../context/Constants";
import { useAuthStateContext } from "../../../context/Auth";
import { TopTripBanner } from "../Trip/components/TopTripBanner";
import {
    getOrganizations,
    selectOrganizations,
} from "../../organizations/organizationSlice";

const schema = yup
    .object({
        title: yup.string().required("This field is required"),
        description: yup.string().max(5000).notRequired(),
        startDate: yup.date().required("This field is required"),
        endDate: yup
            .date()
            .min(yup.ref("startDate"), "End date must be after Start date")
            .required("This field is required"),
    })
    .required("This field is required");

export default function TripFormComponent({
    trip,
    onSubmit,
    onCancel,
    fromLibrary,
    organizationId,
}: any) {
    const [selectedFile, setSelectedFile] = useState<any>(undefined);
    const customInvite = useAppSelector(selectedTripCustomInvite);
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const { checkNewDatesBeforeSubmit } = useTripUpdateDatesContext();
    const { constants } = useConstantsContext();

    const organizations = useSelector(selectOrganizations);
    const organizationOptions = useMemo(() => {
        const orgs = organizations
            ?.filter((o) => {
                const meOnOrg = o.users?.find(
                    (m: any) => m.userEmail === userProfile?.email
                );
                return meOnOrg?.status === UserStatusOnOrganization.CONFIRMED;
            })
            ?.map((o) => {
                return {
                    value: o?.id,
                    label: o?.name,
                };
            });

        orgs.sort((a: any, b: any) =>
            (a?.label || "").localeCompare(b?.label || "")
        );

        if (orgs.length > 1) {
            orgs.unshift({ value: null, label: "None" });
        }

        return orgs;
    }, [organizations, userProfile?.email]);

    const [startDate, setStartDate] = useState<any>(new Date());
    const [organization, setOrganization] = useState<any>(null);
    const dispatch = useDispatch();
    const {
        register,
        control,
        formState: { errors, isValid },
        handleSubmit,
        trigger,
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const {
        tripsLimitReached,
        getTripsCount,
        setShowTripsReachedModal,
        isFreeSupplier,
        setShowPlanModal,
    } = useAuthStateContext();
    useEffect(() => {
        dispatch(getOrganizations(false));
        getTripsCount && getTripsCount();
    }, []);

    useEffect(() => {
        if (!fromLibrary && !organizationId) {
            return;
        }
        const org = organizationOptions.find(
            (o: { value: any }) => o.value === organizationId
        );
        if (!org) {
            return;
        }
        setOrganization(org);
        setValue("organizationId", organizationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, organizationOptions, fromLibrary]);

    useEffect(() => {
        if (trip) {
            const owner = trip.users.find(
                (u: { role: string }) => u.role === "OWNER"
            );

            if (owner && owner.organizationId) {
                setOrganization(
                    organizationOptions.find(
                        (o: { value: any }) => o.value === owner.organizationId
                    )
                );
            } else {
                setOrganization(null);
            }

            reset({
                title: trip.title,
                description: trip.description,
                type: trip.type,

                startDate: new Date(
                    moment.utc(trip.startDate).year(),
                    moment.utc(trip.startDate).month(),
                    moment.utc(trip.startDate).date()
                ),
                endDate: new Date(
                    moment.utc(trip.endDate).year(),
                    moment.utc(trip.endDate).month(),
                    moment.utc(trip.endDate).date()
                ),

                invitationText: customInvite?.description,
                organizationId: owner?.organizationId || null,
            });
            setStartDate(
                new Date(
                    moment.utc(trip.startDate).year(),
                    moment.utc(trip.startDate).month(),
                    moment.utc(trip.startDate).date()
                )
            );
            setSelectedFile(null);
        } else {
            reset({
                startDate: new Date(),
                endDate: moment(new Date()).add(1, "day").toDate(),
            });
            setSelectedFile(TRIP_DEFAULT_IMAGES[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip]);

    const handleSubmitForm = async (data: any) => {
        const defaultOrg =
            organizationOptions.length === 1
                ? organizationOptions[0].value
                : data.organizationId;

        let payload = {
            ...data,
            startDate: moment(data?.startDate).format("yyyy-MM-DD"),
            endDate: moment(data?.endDate).format("yyyy-MM-DD"),
            organizationId: defaultOrg,
        };

        if (selectedFile?.id) payload["coverImage_default"] = selectedFile?.id;
        else if (selectedFile) payload["coverImage"] = selectedFile;
        if (trip) {
            try {
                dispatch(isLoading(true));
                const response = await server.getTripDatesLimit(trip.id);
                dispatch(isLoading(false));
                checkNewDatesBeforeSubmit(
                    payload,
                    trip,
                    onSubmit,
                    response.data
                );
            } catch (error: any) {
                dispatch(isLoading(false));
                sendToast(
                    TOAST_TYPE.ERROR,
                    "Error getting trip info. Please try again in a few minutes."
                );
            }
        } else {
            if (tripsLimitReached) {
                setShowTripsReachedModal(true);
                return;
            }
            onSubmit && onSubmit(payload);
        }
    };

    const onSelectFile = (imageSrc: any) => {
        setSelectedFile(imageSrc);
        return;
    };

    const sdMinDate = useMemo(() => {
        return !trip?.id
            ? moment(moment.now()).startOf("day").toDate()
            : undefined;
    }, [trip]);
    return (
        <div className="mb-6">
            {!!isFreeSupplier && !tripsLimitReached && (
                <div className="mb-4">
                    <TopTripBanner
                        hideIcon
                        title="You're creating your one-time free trip on FamGuru!"
                        text="Explore FamGuru's full potential and find the perfect plan for future trips."
                        action={() => {
                            setShowPlanModal(true);
                        }}
                        actionLabel="View Plans"
                        className={"flex flex-row justify-between items-center"}
                        actionWrapperClass="!mt-0"
                    />
                </div>
            )}

            <div className="flex flex-wrap mb-6">
                <div className="text-left font-semibold text-lg flex-grow">
                    Trip Information
                </div>
            </div>

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="w-full">
                    <TripImagesInput
                        trip={trip}
                        handleSelectImage={onSelectFile}
                        handleSelectDefaultImage={onSelectFile}
                    />
                </div>
                <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-12 mt-10">
                    <div className="flex flex-col lg:col-span-3">
                        <Input
                            register={register}
                            label="Name"
                            type="text"
                            id="title"
                            name="title"
                            error={errors["title"]?.message}
                            markRequired
                        />
                        <div className="mt-8">
                            <DatePickerMaterial
                                control={control}
                                minDate={sdMinDate}
                                name="startDate"
                                id="startDate"
                                label="Start date"
                                onChange={(val) => {
                                    setStartDate(val);
                                    trigger("endDate");
                                    trigger("startDate");
                                }}
                                error={errors["startDate"]?.message}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-end lg:col-span-3">
                        {constants?.featureFlags?.ORGANIZATIONS &&
                            (organizationOptions.length > 1 ? (
                                <div className="flex items-center justify-between">
                                    <div className="flex-grow">
                                        <SelectInputWithValid
                                            control={control}
                                            name="organizationId"
                                            label="Organization"
                                            options={organizationOptions}
                                            error={
                                                errors["organizationId"]
                                                    ?.message
                                            }
                                            value={organization}
                                            isDisabled={
                                                fromLibrary &&
                                                !!organizationId &&
                                                !!organization
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <input
                                    type="hidden"
                                    {...register("organizationId")}
                                    value={organization}
                                />
                            ))}

                        <div className="mt-8">
                            <DatePickerMaterial
                                control={control}
                                minDate={
                                    moment
                                        .utc(startDate)
                                        .add(1, "day")
                                        .toDate() as any
                                }
                                onChange={() => {
                                    trigger("endDate");
                                    trigger("startDate");
                                }}
                                name="endDate"
                                id="endDate"
                                label="End date"
                                error={errors["endDate"]?.message}
                            />
                        </div>
                    </div>

                    <div className="col-span-6 lg:col-span-3"></div>

                    {userProfile?.userType === UserTypeVariant.SUPPLIER && (
                        <div className="col-span-6">
                            <TextAreaInput
                                {...register("invitationText", {
                                    required: true,
                                })}
                                label="Trip invitation text"
                                id="invitationText"
                                name="invitationText"
                                tooltip={
                                    "Text that will be shown on the trip invitation"
                                }
                                placeholder={
                                    "When adding members to your trip, they will automatically receive an invitation to your trip with the text you provide here. We suggest creating your invitation text before adding members."
                                }
                                error={errors["invitationText"]?.message}
                                maxLength="5000"
                            />
                        </div>
                    )}
                    <div className="col-span-6">
                        <TextAreaInput
                            {...register("description", { required: true })}
                            label="Trip Description"
                            id="description"
                            name="description"
                            tooltip={"Brief description of the trip"}
                            error={errors["description"]?.message}
                            maxLength="5000"
                        />
                    </div>
                </div>

                <div className="px-4 pb-10 lg:pb-0 pt-10 lg:pt-24 text-right w-full flex justify-end gap-6">
                    <Button
                        onClick={() => onCancel(trip?.id)}
                        outlined
                        label="Cancel"
                    />
                    <Button
                        disabled={!isValid}
                        type="submit"
                        label={trip ? "Save" : "Create"}
                    />
                </div>
            </form>
        </div>
    );
}
