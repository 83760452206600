import React, { useState } from "react";
import { IPlan } from "../../types/plan.type";
import {
    isLoadingData,
} from "../../features/plans/plansSlice";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
} from "@stripe/react-stripe-js";
import PaymentStatusModal from "./PaymentStatusModal"; 
import Modal, { Modal_Size } from ".";
import { useAppSelector } from "../../app/hooks";
import { PaymentForm } from "./Payment/PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface PaymentSubscriptionProps {
    selectedPlan: IPlan;
    handleSetPLan: (plan: any) => void;
    isFromAdmin?: boolean;
    user?: any;
}

const PaymentSubscription: React.FC<PaymentSubscriptionProps> = ({
    selectedPlan,
    handleSetPLan,
    isFromAdmin,
    user,
}) => {
    const isLoading = useAppSelector(isLoadingData);
    
    const [paymentStatus, setPaymentStatus] = useState<
        "success" | "failed" | null
    >(null);
    const [description, setDescription] = useState<string | null>(null);

    const { offerData } = selectedPlan.data;


    const handleCloseModal = () => {
        paymentStatus !== "failed" && handleSetPLan(selectedPlan);
        paymentStatus === "success" && window.location.reload();
        setPaymentStatus(null);
    };

    const handleCallbackPayment = (description: string, paymentStatus: string | null) => {
        setDescription(description);
        setPaymentStatus(paymentStatus as "success" | "failed");
    }


    return (
        <div className="p-2">
            <h2 className="text-2xl font-semibold mb-2 text-left">
                {selectedPlan?.name}
            </h2>

            <p className="text-left text-gray-600 mb-2">
                Subscribe to {selectedPlan?.name} Monthly
            </p>

            <div className="flex items-baseline mb-4">
                <span className="text-4xl font-bold text-left">
                    ${offerData.price}
                </span>
                <span className="text-sm text-gray-500 ml-2">
                    {!!offerData?.priceDescription?.length && "/"}
                    {offerData?.priceDescription}
                </span>
            </div>

            <hr className="my-4" />
            <PaymentForm
                isLoading={isLoading}
                selectedPlan={selectedPlan}
                handleCallbackPayment={handleCallbackPayment}
                isFromAdmin={isFromAdmin}
                user={user}
            />

            <p className="text-xs text-gray-400 text-left mt-2">
                By confirming your subscription, you allow FamGuru to charge you
                for future payments in accordance with our terms. You can always
                cancel your subscription.
            </p>

            <hr className="my-4" />

            <div className="text-center text-xs text-gray-500">
                Powered by <span className="font-semibold">Stripe</span> |{" "}
                <a
                    href="https://famguru.app/terms"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms
                </a>{" "}
                |{" "}
                <a
                    href="https://famguru.app/privacy-policy"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy
                </a>
            </div>

            <Modal
                open={paymentStatus !== null}
                size={Modal_Size.xs}
                onClose={handleCloseModal}
            >
                <PaymentStatusModal
                    status={paymentStatus}
                    description={description}
                    onClose={handleCloseModal}
                    planName={selectedPlan.name}
                    price={`$${offerData.price}`}
                />
            </Modal>
        </div>
    );
};

const PaymentSubscriptionWrapper: React.FC<PaymentSubscriptionProps> = ({
    selectedPlan,
    handleSetPLan,
    isFromAdmin = false,
    user,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentSubscription
                selectedPlan={selectedPlan}
                handleSetPLan={handleSetPLan}
                isFromAdmin={isFromAdmin}
                user={user}
            />
        </Elements>
    );
};

export default PaymentSubscriptionWrapper;
