import { PAGE_SECTIONS } from "../constants";
import { BreadcrumbOption } from "../features/authentication/userSlice";

export const isUpdateForm = (pathname: string): boolean => {
    const pathLength = pathname.length;
    const lastPartPath = pathname.substring(pathLength - 4, pathLength);
    return lastPartPath === "edit";
};

interface IPathProps {
    tripId?: number;
    userId?: number;
    activityId?: number;
    tripTitle?: string;
    activityTitle?: string;
    organizationId?: number;
    organizationName?: string;
    libraryElementTitle?: string;
    activityType?: string;
}
export const getBasePath = (pageSection: PAGE_SECTIONS, props: IPathProps) => {
    let response = "";
    switch (pageSection) {
        case PAGE_SECTIONS.ORGANIZATIONS:
        case PAGE_SECTIONS.ORGANIZATION:
        case PAGE_SECTIONS.ORGANIZATION_TRIPS:
        case PAGE_SECTIONS.ORGANIZATION_MEMBERS:
        case PAGE_SECTIONS.ORGANIZATION_LIBRARY:
        case PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS:
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL:
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT:
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT:
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER:
        case PAGE_SECTIONS.ORGANIZATION_CHAT:
            response = `organizations/${props.organizationId}/`;
            break;
        case PAGE_SECTIONS.PROFILE:
            response = "";
            break;
        case PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW:
            response = `trips/${props.tripId}/itinerary/${props.activityId}/`;
            break;
        case PAGE_SECTIONS.TRIP_CUSTOM_INVITE:
        case PAGE_SECTIONS.TRIP_CONTACTS:
        case PAGE_SECTIONS.TRIP_ITINERARY:
        case PAGE_SECTIONS.TRIP_LOCAL_PARTNERS:
        case PAGE_SECTIONS.TRIP_PARTICIPANTS:
        case PAGE_SECTIONS.TRIP_REQUIREMENTS:
        case PAGE_SECTIONS.TRIP:
        case PAGE_SECTIONS.TRIP_GALLERY:
        case PAGE_SECTIONS.TRIP_NEW:
        case PAGE_SECTIONS.TRIP_EDIT:
        case PAGE_SECTIONS.PENDING_TRIP:
        case PAGE_SECTIONS.TRIP_FILES:
        case PAGE_SECTIONS.TRIP_FILES_EDIT:
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL:
        case PAGE_SECTIONS.TRIP_ITINERARY_VISIT:
        case PAGE_SECTIONS.TRIP_ITINERARY_OTHER:
        case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT:
        case PAGE_SECTIONS.TRIP_NOTIFICATIONS:
        case PAGE_SECTIONS.TRIP_MESSAGES:
        case PAGE_SECTIONS.TRIP_CHAT:
            response = `trips/${props.tripId}/`;
            break;
    }
    return response;
};

export const getBreadcrumbOptions = (
    pageSection: PAGE_SECTIONS,
    props: IPathProps
): BreadcrumbOption[] => {
    switch (pageSection) {
        case PAGE_SECTIONS.HOME:
            return [
                {
                    label: "Home",
                    url: "/",
                },
            ];
        case PAGE_SECTIONS.TRIPS:
        case PAGE_SECTIONS.TRIP_NEW:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Trips",
                    url: "/trips",
                },
            ];
        case PAGE_SECTIONS.INSIGHTS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Insights",
                    url: "/insights",
                },
            ];
        case PAGE_SECTIONS.NOTIFICATIONS_USER:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Notifications",
                    url: "/notifications",
                },
            ];
        case PAGE_SECTIONS.PROFILE:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Profile",
                    url: "/profile",
                },
            ];
        case PAGE_SECTIONS.ORGANIZATIONS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Organizations",
                    url: "/organizations",
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.ORGANIZATIONS, props),
                {
                    label: props?.organizationName || "Organization",
                    url: `/organizations/${props.organizationId}`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_MEMBERS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.ORGANIZATION, props),
                {
                    label: "Members",
                    url: `/organizations/${props.organizationId}/members`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_TRIPS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.ORGANIZATION, props),
                {
                    label: "Library",
                    url: `/organizations/${props.organizationId}/trips`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_LIBRARY:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.ORGANIZATION, props),
                {
                    label: "Library",
                    url: `/organizations/${props.organizationId}/library`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.ORGANIZATION_LIBRARY,
                    props
                ),
                {
                    label: props?.libraryElementTitle || "Library Element",
                    url: `/organizations/${props.organizationId}/library/${props.activityType}/${props.activityId}`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.ORGANIZATION_LIBRARY,
                    props
                ),
                {
                    label: "Add Hotel",
                    url: `/organizations/${props.organizationId}/library/HOTEL/new`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.ORGANIZATION_LIBRARY,
                    props
                ),
                {
                    label: "Add Site Inspection",
                    url: `/organizations/${props.organizationId}/library/HOTEL/new`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.ORGANIZATION_LIBRARY,
                    props
                ),
                {
                    label: "Add Restaurant",
                    url: `/organizations/${props.organizationId}/library/RESTAURANT/new`,
                },
            ];
        case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.ORGANIZATION_LIBRARY,
                    props
                ),
                {
                    label: "Add Other",
                    url: `/organizations/${props.organizationId}/library/OTHER/new`,
                },
            ];
        case PAGE_SECTIONS.PREFERENCES_USER:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Preferences",
                    url: "/preferences",
                },
            ];
        case PAGE_SECTIONS.PLAN_USER:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "My Plan",
                    url: "/plans",
                },
            ];
        case PAGE_SECTIONS.MANAGE_ACCOUNT:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: "Manage account",
                    url: "/account",
                },
            ];
        case PAGE_SECTIONS.TRIP:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.HOME, props),
                {
                    label: props?.tripTitle || "Trip Details",
                    url: `/trips/${props.tripId}`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP_ITINERARY, props),
                {
                    label: "Hotel Stay",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP_ITINERARY, props),
                {
                    label: "Restaurant",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_OTHER:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP_ITINERARY, props),
                {
                    label: "Other Activity",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Itinerary",
                    url: `/trips/${props.tripId}/itinerary`,
                },
            ];
        case PAGE_SECTIONS.TRIP_CHAT:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Chat",
                    url: `/trips/${props.tripId}/chat`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_VISIT:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP_ITINERARY, props),
                {
                    label: "Site Inspection",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP_ITINERARY, props),
                {
                    label: props?.activityTitle || "Activity",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Insight",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/details`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Contacts",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/contacts`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Rooms",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/rooms`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Dining & Drinking",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/dining-drinking`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Wellness",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/wellness`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Common Areas",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-hotel-insight/common-areas`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Details",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-restaurant-insight`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Contacts",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-restaurant-insight/contacts`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Experience",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-restaurant-insight/experience`,
                },
            ];
        case PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS:
            return [
                ...getBreadcrumbOptions(
                    PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW,
                    props
                ),
                {
                    label: "Details",
                    url: `/trips/${props.tripId}/itinerary/${props.activityId}/add-private-other-insight`,
                },
            ];
        case PAGE_SECTIONS.TRIP_PARTICIPANTS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Members",
                    url: `/trips/${props.tripId}/users`,
                },
            ];
        case PAGE_SECTIONS.TRIP_MESSAGES:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Broadcast",
                    url: `/trips/${props.tripId}/messages`,
                },
            ];
        case PAGE_SECTIONS.TRIP_GALLERY:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Gallery",
                    url: `/trips/${props.tripId}/images`,
                },
            ];
        case PAGE_SECTIONS.TRIP_FILES:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Files",
                    url: `/trips/${props.tripId}/files`,
                },
            ];
        case PAGE_SECTIONS.TRIP_REQUIREMENTS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Requirements",
                    url: `/trips/${props.tripId}/requirements`,
                },
            ];
        case PAGE_SECTIONS.TRIP_NOTIFICATIONS:
            return [
                ...getBreadcrumbOptions(PAGE_SECTIONS.TRIP, props),
                {
                    label: "Notifications",
                    url: `/trips/${props.tripId}/notifications`,
                },
            ];
        default:
            return [];
    }
};
