import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/ButtonSquare";
import EmptyScreen from "../../../../components/EmptyScreen/EmptyScreen";
import Modal from "../../../../components/Modal";
import { ButtonSize } from "../../../../constants";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import {
    removeRequirementFromTrip,
    selectedTrip,
    tripRequirements,
} from "../../tripSlice";
import TripRequirementsList from "./TripRequirementsList";
import TripRequirementsTable from "./TripRequirementsTable";

export default function TripRequirements({ handlePaginatorChange, handleNext }: any) {
    const requirements = useAppSelector(tripRequirements);
    const trip = useAppSelector(selectedTrip);
    const [deleteReqModalOpen, setDeleteReqModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useAppSelector(selectCurrentBreakpoint);
    const { data, isLoading } =
        requirements;
    const [requirementToDelete, setRequirementToDelete] = useState<any>();

    const handleViewClick = (reqId: number) => {
        navigate(`${reqId}`);
    };

    const handleDeleteModalClose = () => {
        setDeleteReqModalOpen(false);
    };

    const handleRemoveClick = async () => {
        if (requirementToDelete?.id) {
            await dispatch(
                removeRequirementFromTrip(
                    Number(trip?.id),
                    requirementToDelete?.id
                )
            );
        }
        handleDeleteModalClose();
    };

    const handleAddFileClick = (requirementId: number) => {
        navigate(`${requirementId}/files/new`);
    };

    const handleRemoveOpenModalClick = (requirement: any) => {
        setRequirementToDelete(requirement);
        setDeleteReqModalOpen(true);
    };

    return (
        <>
            <Modal open={deleteReqModalOpen} onClose={handleDeleteModalClose}>
                <div className="px-6">
                    <div className="text-base font-semibold text-gray-600">
                        Are you sure you want to remove this requirement?
                    </div>
                    <div className="text-base font-base">
                        "{requirementToDelete?.name}"
                    </div>
                    <div className="px-4 mt-8 text-right">
                        <span className="mr-4">
                            <Button
                                outlined={true}
                                label="Cancel"
                                onClick={handleDeleteModalClose}
                                size={ButtonSize.SMALL}
                            />
                        </span>
                        <Button
                            label="Remove"
                            onClick={handleRemoveClick}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </Modal>

            {requirements ? (
                !isMobile ? (
                    <TripRequirementsTable
                        requirements={requirements}
                        handleViewClick={handleViewClick}
                        handleAddFileClick={handleAddFileClick}
                        handleRemoveClick={handleRemoveOpenModalClick}
                        handlePaginatorChange={handlePaginatorChange}
                    />
                ) : (
                    <TripRequirementsList
                        requirements={requirements}
                        handleViewClick={handleViewClick}
                        handleAddFileClick={handleAddFileClick}
                        handleRemoveClick={handleRemoveOpenModalClick}
                        handleNext={handleNext}
                    />
                )
            ) : null}

            {!data?.length && !isLoading && (
                <EmptyScreen text="There are no requirements to show." />
            )}
        </>
    );
}
